/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { b64EncodeUnicode } from 'utils/formatters'
import { enumify, GQLVariable, plutoFormMutation, plutoMutation, stringify } from './gqlInterface'
import { MutationResponse, mutationResponseString } from './types'

type RewardsTriggerInput = {
    id?: string
    title: string
    description?: string
    type: string
    basis: string
    spend?: number
    value: number
    pin?: string
    file?: File
}

type RewardsGoalInput = {
    id?: string
    title: string
    value: number
    acknowledgementMessage: string
    termsAndConditions: string
    acknowledgementSMS: boolean
}

type UpdateRewardsProductInput = {
    accountID: string
    productID?: string
    triggers: RewardsTriggerInput[]
    goals: RewardsGoalInput[]
    max: number
    pointsPerStamp: number
    checkinOn: boolean
    checkoutOn: boolean
    checkoutEstimateOn: boolean
    surveyOn: boolean
    surveyDelay?: number
    surveyFrequency?: number
    followUpSurveyOn: boolean
    followUpSurveyThreshold: number
    followUpSurveyUrl?: string
    cardColor?: string
    displayCardName: boolean
    useDefaultCardBackground: boolean
    removeCardBackground?: boolean
    manualAwardOn: boolean
    manualAwardMax?: number
}
export const updateRewardsProduct = async (
    input: UpdateRewardsProductInput,
    productTerms: string,
    triggerFiles: (File | null)[],
    cardCoverFile: File | null = null
) =>
    plutoFormMutation(
        `
            mutation($productTerms: String!, $triggerFiles: [Upload]!, $cardCoverFile: Upload) {
                updateRewardsProduct(input: ${stringify(
                    input
                )}, productTerms: $productTerms, triggerFiles: $triggerFiles, cardCoverFile: $cardCoverFile) {
                    success
                    message
                }
            }
        `,
        {
            productTerms: b64EncodeUnicode(productTerms),
            triggerFiles: triggerFiles.map((tf) => tf ?? String(tf)),
            cardCoverFile: cardCoverFile ?? String(cardCoverFile),
        }
    )

type UpdateRewardsTriggerInput = {
    accountID: string
    productID: string
    trigger: RewardsTriggerInput
}
export const updateRewardsTrigger = async (input: UpdateRewardsTriggerInput) =>
    plutoFormMutation(
        `
            mutation($triggerFile: Upload, $description: String) {
                updateRewardsTrigger(input: ${stringify({
                    ...input,
                    trigger: { ...input.trigger, file: new GQLVariable('$triggerFile'), description: new GQLVariable('$description') },
                })}) ${mutationResponseString}
            }
        `,
        { triggerFile: input.trigger.file ?? null, description: input.trigger.description ?? null }
    )

type DeleteRewardsTriggerInput = {
    accountID: string
    productID: string
    triggerID: string
}
export const deleteRewardsTrigger = async (input: DeleteRewardsTriggerInput) =>
    plutoMutation`deleteRewardsTrigger(input: ${input}) ${mutationResponseString}`

type UpdateRewardsGoalInput = {
    accountID: string
    productID: string
    goal: RewardsGoalInput
}
type UpdateRewardsGoalResponse = MutationResponse & { goalID?: string }

export const updateRewardsGoal = async (input: UpdateRewardsGoalInput) =>
    plutoFormMutation<UpdateRewardsGoalResponse>(
        `
            mutation($termsAndConditions: String) {
                updateRewardsGoal(input: ${stringify({
                    ...input,
                    goal: { ...input.goal, termsAndConditions: new GQLVariable('$termsAndConditions') },
                })}) {
                    success
                    message
                    goalID
                }
            }
        `,
        { termsAndConditions: input.goal.termsAndConditions ?? null }
    )

type DeleteRewardsGoalInput = {
    accountID: string
    productID: string
    goalID: string
}
export const deleteRewardsGoal = async (input: DeleteRewardsGoalInput) => plutoMutation`deleteRewardsGoal(input: ${input}) ${mutationResponseString}`

type RewardsInventoryItemInput = {
    id?: string
    name: string
    description?: string
    quantity: number
    file?: File | null
    limit?: number
}
type AddInventoryItemsInput = {
    accountID: string
    productID: string
    inventoryItems: RewardsInventoryItemInput[]
    goalID: string
}
export const addRewardsInventoryItems = async (input: AddInventoryItemsInput) =>
    plutoFormMutation(
        `mutation(${input.inventoryItems.map((_, i) => `$i${i}: Upload`).join(', ')}) {
            addRewardsGoalInventoryItems(input: ${stringify({
                ...input,
                inventoryItems: input.inventoryItems.map((h, i) => ({ ...h, file: new GQLVariable(`$i${i}`) })),
            })}) {
                success
                message
            }
        }`,
        input.inventoryItems.reduce((t, c, i) => ({ ...t, [`i${i}`]: c.file }), {})
    )

type EditInventoryItemsInput = {
    accountID: string
    productID: string
    inventoryItems: RewardsInventoryItemInput[]
    goalID: string
}
export const editRewardsInventoryItems = async (input: EditInventoryItemsInput) =>
    plutoFormMutation(
        `mutation(${input.inventoryItems.map((_, i) => `$i${i}: Upload`).join(', ')}) {
            editRewardsGoalInventoryItems(input: ${stringify({
                ...input,
                inventoryItems: input.inventoryItems.map((h, i) => ({ ...h, file: new GQLVariable(`$i${i}`) })),
            })}) {
                success
                message
            }
        }`,
        input.inventoryItems.reduce((t, c, i) => ({ ...t, [`i${i}`]: c.file }), {})
    )

type DeleteInventoryItemsInput = {
    accountID: string
    productID: string
    goalID: string
    inventoryItems: string[]
}
export const deleteRewardsInventoryItems = async (input: DeleteInventoryItemsInput) =>
    plutoMutation`deleteRewardsGoalInventoryItems(input: ${input}) ${mutationResponseString}`

type SubmitRewardsClaimInput = {
    accountID: string
    productID: string
    triggerID: string
    holdingID: string
    basis?: string
}
export const submitRewardsClaim = async (input: SubmitRewardsClaimInput) => plutoMutation`
    submitRewardsClaim(input: ${input}) {
        success
        message
    }
`

type DecisionRewardsClaimInput = {
    accountID: string
    claimID: string
    decision: 'approve' | 'decline'
}
export const decisionRewardsClaim = async (input: DecisionRewardsClaimInput) => plutoMutation`
    decisionRewardsClaim(input: ${input}) {
        success
        message
    }
`

export const redemptionStatuses = [`Awaiting`, `Delivered`, `Cancelled`, `Refunded`] as const
export type RedemptionStatus = (typeof redemptionStatuses)[number]
export const isRedemptionStatus = (value: unknown): value is RedemptionStatus => {
    if (typeof value !== 'string') return false
    if (!redemptionStatuses.includes(value as RedemptionStatus)) return false
    return true
}
type UpdateRewardsRedemptionStatusInput = {
    accountID: string
    redemptionID: string
    status: RedemptionStatus
    reason: string
}
export const updateRewardsRedemptionStatus = async ({ accountID, redemptionID, status, reason }: UpdateRewardsRedemptionStatusInput) => plutoMutation`
    updateRewardsRedemptionStatus(input: {accountID:"${accountID}",redemptionID:"${redemptionID}",reason:"${reason}",status:${enumify(
    status
)}}) ${mutationResponseString}
`

type CreateCheckinInput = {
    accountID: string
    productID: string
    storeID?: string | null
    estimate?: number
}
export const checkin = async (input: CreateCheckinInput) => plutoMutation`
    createCheckin(input: ${input}) ${mutationResponseString}
`

type CreateCheckoutInput = {
    accountID: string
    checkinID: string
}
export const checkout = async (input: CreateCheckoutInput) => plutoMutation`
    createCheckout(input: ${input}) ${mutationResponseString}
`

type ClaimTriggerOnClickInput = {
    accountID: string
    holdingID: string
    productID: string
    triggerID: string
}
export const claimTriggerOnClick = async (input: ClaimTriggerOnClickInput) => plutoMutation`
    claimTriggerOnLink(input: ${input}) ${mutationResponseString}
`
