/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

type OfferDescriptionProps = {
    description: string
}
export default function OfferDescription({ description }: OfferDescriptionProps) {
    // eslint-disable-next-line react/no-danger
    return <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: description }} />
}
