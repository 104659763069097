/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import QR from 'react-qr-code'
import { Button, FlexBox, SuccessSwal } from 'vspry-style-components'

import { useLocale } from 'context/localeContext'

import theme from 'styles/theme'

import StyledIcon from './WheelArrow'

const StyledQR = styled(QR)`
    width: 100%;
`

export default function QRCode({ code, noDownload, noCopy, onNext, onBack }) {
    const svgRef = useRef()
    const { translate } = useLocale()

    const downloadBlob = (blob) => {
        const objectUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = objectUrl
        link.download = `QRcode.svg`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setTimeout(() => URL.revokeObjectURL(objectUrl), 5000)
    }

    const handleDownload = () => {
        const svg = svgRef.current.innerHTML
        const blob = new Blob([svg], { type: 'image/svg+xml' })
        downloadBlob(blob)
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(code)
        SuccessSwal.fire({ title: translate('pages.rewardsSetup.qr.linkCopied') })
    }

    return (
        <FlexBox $column gap='small'>
            <FlexBox gap='small' justify='center' $align>
                {onBack && <StyledIcon name='chevron left' size='medium' onClick={onBack} />}
                <div ref={svgRef} style={{ height: '256px' }}>
                    <StyledQR value={code} bgColor='rgba(0, 0, 0, 0)' />
                </div>
                {onNext && <StyledIcon name='chevron right' size='medium' onClick={onNext} />}
            </FlexBox>
            <FlexBox gap='xSmall' $column>
                {!noDownload && (
                    <Button customColor={`${theme.buttonBG}40`} textColor='text' onClick={handleDownload}>
                        {translate('buttons.download')}
                    </Button>
                )}
                {!noCopy && (
                    <Button customColor={`${theme.disabledButtonBG}40`} textColor='text' onClick={handleCopy}>
                        {translate('pages.rewardsSetup.qr.copyLink')}
                    </Button>
                )}
            </FlexBox>
        </FlexBox>
    )
}

QRCode.propTypes = {
    code: PropTypes.string.isRequired,
    noDownload: PropTypes.bool,
    noCopy: PropTypes.bool,
    onNext: PropTypes.func,
    onBack: PropTypes.func,
}

QRCode.defaultProps = {
    noDownload: false,
    noCopy: false,
    onNext: undefined,
    onBack: undefined,
}
