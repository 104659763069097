/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

type AwardRedemptionsListItemTermsProps = {
    termsAndConditions: string
}
export default function AwardRedemptionsListItemTerms({ termsAndConditions }: AwardRedemptionsListItemTermsProps) {
    // eslint-disable-next-line react/no-danger
    return <div style={{ textAlign: 'left', maxHeight: '150px', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
}
