/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { UiNode } from '@ory/client'
import { Helmet } from 'react-helmet'
import { Type } from 'services/auth/interface'
import { Input, isInputNode } from './Input'
import Link, { isLinkNode } from './Link'
import Image, { isImageNode } from './Image'
import DisplayText, { isDisplayTextNode } from './DisplayText'

type NodeProps = {
    node: UiNode
    flow: Type
}
export default function Node({ node, flow }: NodeProps) {
    if (isImageNode(node)) return <Image {...node} />
    if (isInputNode(node)) return <Input {...{ ...node, flow }} />
    if (isLinkNode(node)) return <Link {...node} />
    if (isDisplayTextNode(node)) return <DisplayText {...node} />
    if (node.attributes.node_type === 'script')
        return (
            <Helmet>
                <script
                    {...node.attributes}
                    src={node.attributes.src.includes('/.well-known/ory/webauthn.js') ? '/lib/webauthn.js' : node.attributes.src}
                    integrity={undefined}
                    type=''
                />
            </Helmet>
        )

    return <div />
}
