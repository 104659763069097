/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Divider, Dropdown } from 'semantic-ui-react'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useBrowserStorage, usePagination } from 'vspry-hooks'
import { Checkbox, FlexBox, Icon, Loader, Pagination, Text } from 'vspry-style-components'
import theme from 'styles/theme'

import { formatDateTimeReadable } from 'utils/formatters'
import { PaginatedResponse, Pagination as Pag } from 'api/types'
import { getAwardTransactions, Holding, IDCard, Redemption } from 'api/common'
import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'

import AwardRedemptionsListItemTerms from 'components/products/reward/AwardRedemptionsListItemTerms'
import DashedDivider from 'components/style-elements/DashedDivider'
import ExpandableTile from 'components/ExpandableTile'
import PageContainer from 'components/style-elements/containers/PageContainer'
import PrettyTile from 'components/PrettyTile'
import StatusBar from 'components/StatusBar'
import TabbedLayout from 'components/TabbedLayout'
import { redemptionStatuses } from 'api/reward'

type RedemptionsInboxByProductProps = {
    getRedemptions: (p: Pag) => Promise<PaginatedResponse<Redemption> | null>
}
// returning only redemption list
function RedemptionsInboxByProduct({ getRedemptions }: RedemptionsInboxByProductProps) {
    const { translate } = useLocale()
    const navigate = useNavigate()
    const [expandedIndex, setExpandedIndex] = useState(0)

    const { fetching, fetchPage, page, currentPage, totalPages, items, setItems } = usePagination(getRedemptions, 5, 'ripi')

    const calculateItemCost = (itemCost: number, itemQuantities: number) => {
        const sum = itemCost * itemQuantities
        const multiples = sum > 1 ? `${translate('pages.redemptionsInbox.points')}` : `${translate('pages.redemptionsInbox.point')}`

        return `${sum} ${multiples}`
    }

    const handleToggle = (index: number) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? -1 : index))
    }

    const renderRewards = () => {
        if (!page || page.length === 0) {
            return (
                <FlexBox justify='center' $align>
                    <Text size='small'>
                        {translate('pages.redemptionsInbox.noRewards')}{' '}
                        <Icon name='gift' size='large' customColor={theme.accent} onClick={() => navigate('/home?tab=rewards')} />
                    </Text>
                </FlexBox>
            )
        }
        return page.map((transaction, index) => (
            <ExpandableTile
                title={transaction.goal}
                status={transaction.status}
                isExpanded={expandedIndex === index}
                onToggle={() => handleToggle(index)}
            >
                <FlexBox $column gap='xSmall' padding='small'>
                    <FlexBox>
                        <FlexBox $column>
                            <Text $left $bold size='xSmall' margin='no'>
                                {translate('pages.redemptionsInbox.merchant')}
                            </Text>
                            <Text $left size='xSmall' margin='no'>
                                {transaction.merchant}
                            </Text>
                        </FlexBox>
                        <FlexBox $column>
                            <Text $left $bold size='xSmall' margin='no'>
                                {translate('pages.redemptionsInbox.user')}
                            </Text>
                            <Text $left size='xSmall' margin='no'>
                                {transaction.contact}
                            </Text>
                        </FlexBox>
                        <FlexBox $column>
                            <Text $left $bold size='xSmall' margin='no'>
                                {translate('pages.redemptionsInbox.date')}
                            </Text>
                            <Text $left size='xSmall' margin='no'>
                                {formatDateTimeReadable(transaction.date)}
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    <DashedDivider />
                    <FlexBox $column gap='medium'>
                        <FlexBox $align>
                            <Text $left $bold size='xSmall' margin='no'>
                                {translate('pages.redemptionsInbox.reward')}
                            </Text>
                            <Text $left size='xSmall' margin='no'>
                                {transaction.goal}
                            </Text>
                        </FlexBox>
                        {!transaction.items.length && (
                            <>
                                <FlexBox $align>
                                    <Text $left $bold size='small' margin='no'>
                                        {translate('pages.redemptionsInbox.total')}
                                    </Text>
                                    <Text $left size='small' $bold margin='no'>
                                        {transaction.value} {transaction.value > 1 ? 'points' : 'point'}
                                    </Text>
                                </FlexBox>
                                {transaction.terms.length > 0 && (
                                    <FlexBox $column>
                                        <Text $left $bold size='xSmall' margin='no'>
                                            {translate('pages.redemptionsInbox.termsAndConditions')}
                                        </Text>
                                        <Text $left size='xSmall' margin='no'>
                                            <AwardRedemptionsListItemTerms termsAndConditions={transaction.terms} />
                                        </Text>
                                    </FlexBox>
                                )}
                            </>
                        )}
                        {transaction.items.length > 0 &&
                            transaction.items.map((item) => (
                                <FlexBox $column gap='xSmall'>
                                    <FlexBox align='center'>
                                        <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <Text size='xSmall' margin='no' $bold>
                                                x {item.quantity}
                                            </Text>
                                            <img
                                                alt=''
                                                src={item.image ? item.image : '/images/default-item-img.jpg'}
                                                style={{ width: '53px', height: '53px', objectFit: 'cover', borderRadius: '5px' }}
                                            />
                                            <FlexBox>
                                                <Text size='xSmall' margin='no'>
                                                    {item.name}
                                                </Text>
                                            </FlexBox>
                                        </span>
                                        <Text margin='no' size='xSmall' $left>
                                            {calculateItemCost(transaction.costPerItem, item.quantity)}
                                        </Text>
                                    </FlexBox>
                                    <Divider fitted />
                                </FlexBox>
                            ))}
                        {transaction.items.length > 0 && (
                            <>
                                <FlexBox>
                                    <Text $left $bold size='small' margin='no'>
                                        {translate('pages.redemptionsInbox.total')}
                                    </Text>
                                    <Text $left size='small' margin='no' $bold>
                                        {transaction.value} {transaction.value > 1 ? 'points' : 'point'}
                                    </Text>
                                </FlexBox>
                                {transaction.terms.length > 0 && (
                                    <FlexBox $column>
                                        <Text $left $bold size='xSmall' margin='no'>
                                            {translate('pages.redemptionsInbox.termsAndConditions')}
                                        </Text>
                                        <Text $left size='xSmall' margin='no'>
                                            <AwardRedemptionsListItemTerms termsAndConditions={transaction.terms} />
                                        </Text>
                                    </FlexBox>
                                )}
                            </>
                        )}
                    </FlexBox>
                    <FlexBox justify='center'>
                        <StatusBar status={transaction.status} options={['Redeemed', 'Awaiting', 'Delivered']} />
                    </FlexBox>
                    {transaction.reason && (
                        <FlexBox $column>
                            <Text $left $bold size='xSmall' margin='no'>
                                {translate('pages.redemptionsInbox.reason', { status: transaction.status })}
                            </Text>
                            <Text $left size='xSmall' margin='no'>
                                {transaction.reason}
                            </Text>
                        </FlexBox>
                    )}
                </FlexBox>
            </ExpandableTile>
        ))
    }

    return (
        <FlexBox $column gap='small'>
            {fetching ? <Loader /> : renderRewards()}
            {!!totalPages && (
                <Pagination
                    currentPage={currentPage + 1}
                    onChange={(a) => fetchPage(a - 1)}
                    totalPages={totalPages}
                    items={items}
                    setItems={setItems}
                />
            )}
        </FlexBox>
    )
}

export const productToRedemptionsTabLabel = (p: Holding | IDCard) => `${p.product} Redemptions`

// returning entire page with tabs for each product held and corresponding redemptions
export default function RedemptionsInbox() {
    const { account, products, setCurrentProduct } = useAuth()
    const { translate } = useLocale()

    const [status, setStatus] = useBrowserStorage('rist', redemptionStatuses.slice(0, 1))
    const [sort, setSort] = useBrowserStorage('riso', 'DESC')

    const memoizedGetPage = useCallback(
        async (p: Pag, holdingID: string) => account && getAwardTransactions(account.id, holdingID, p, status, sort),
        [account, status, sort]
    )

    return (
        <PageContainer title={translate('pages.redemptionsInbox.title')} size='large'>
            <FlexBox $column gap='medium'>
                <PrettyTile
                    title={translate('pages.redemptionsInbox.title')}
                    titleRight={
                        <Dropdown trigger={<Icon name='filter' color='accent' />} icon=''>
                            <Dropdown.Menu direction='left'>
                                <Dropdown.Header icon='filter' content={translate('inputs.filter')} />
                                <Dropdown.Divider />
                                <Dropdown.Item
                                    onClick={() =>
                                        setStatus(status.includes('Awaiting') ? status.filter((s) => s !== 'Awaiting') : [...status, 'Awaiting'])
                                    }
                                >
                                    <Text size='xSmall' margin='no'>
                                        <Checkbox
                                            id='RI-awaiting-status-check'
                                            label={translate('pages.redemptionsInbox.awaiting')}
                                            checked={status.includes('Awaiting')}
                                        />
                                    </Text>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        setStatus(status.includes('Delivered') ? status.filter((s) => s !== 'Delivered') : [...status, 'Delivered'])
                                    }
                                >
                                    <Text size='xSmall' margin='no'>
                                        <Checkbox
                                            id='RI-delivered-status-check'
                                            label={translate('pages.redemptionsInbox.delivered')}
                                            checked={status.includes('Delivered')}
                                        />
                                    </Text>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        setStatus(status.includes('Cancelled') ? status.filter((s) => s !== 'Cancelled') : [...status, 'Cancelled'])
                                    }
                                >
                                    <Text size='xSmall' margin='no'>
                                        <Checkbox
                                            id='RI-cancelled-status-check'
                                            label={translate('inputs.cancelled')}
                                            checked={status.includes('Cancelled')}
                                        />
                                    </Text>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() =>
                                        setStatus(status.includes('Refunded') ? status.filter((s) => s !== 'Refunded') : [...status, 'Refunded'])
                                    }
                                >
                                    <Text size='xSmall' margin='no'>
                                        <Checkbox
                                            id='RI-refunded-status-check'
                                            label={translate('inputs.refunded')}
                                            checked={status.includes('Refunded')}
                                        />
                                    </Text>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Header icon='sort' content={translate('inputs.sort')} />
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={() => setSort(sort === 'ASC' ? '' : 'ASC')}>
                                    <Text size='xSmall' margin='no'>
                                        <Checkbox id='OAL-ascending-sort-check' label={translate('inputs.ascending')} checked={sort === 'ASC'} />
                                    </Text>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => setSort(sort === 'DESC' ? '' : 'DESC')}>
                                    <Text size='xSmall' margin='no'>
                                        <Checkbox id='OAL-descending-sort-check' label={translate('inputs.descending')} checked={sort === 'DESC'} />
                                    </Text>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    descriptions={[translate('pages.redemptionsInbox.description')]}
                >
                    <TabbedLayout
                        clear
                        tabs={products.map((product) => ({
                            label: productToRedemptionsTabLabel(product),
                            children: <RedemptionsInboxByProduct getRedemptions={(p) => memoizedGetPage(p, product.id)} key={product.id} />,
                        }))}
                        onChange={(t) => setCurrentProduct(products.find((p) => productToRedemptionsTabLabel(p) === t.label) ?? null)}
                    />
                </PrettyTile>
            </FlexBox>
        </PageContainer>
    )
}
