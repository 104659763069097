/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import ReactECharts from 'echarts-for-react'

import { Offer } from 'api/common'

export default function OfferStatistics({ statistics }: { statistics: NonNullable<Offer['statistics']> }) {
    const options = {
        tooltip: {
            trigger: `axis`,
            axisPointer: {
                type: `shadow`,
            },
            formatter(params: any) {
                const tar = params[0]
                if (tar.name === 'Prospects') return `${tar.name}: ${tar.value}`
                return `${tar.name}: ${tar.value} (${Math.round((tar.value / statistics.prospects) * 100)}%)`
            },
        },
        xAxis: {
            type: `value`,
        },
        yAxis: {
            type: `category`,
            data: [`Prospects`, `Clicks`, `Activations`, `Redemptions`],
            inverse: true,
            axisTick: {
                show: false,
            },
            axisLabel: {
                inside: true,
                color: `#FFFFFF`,
                textBorderColor: '#555',
                textBorderWidth: 3,
            },
            z: 3,
        },
        series: [
            {
                data: [statistics.prospects, statistics.clicks.length, statistics.activations.length, statistics.redemptions.length],
                type: `bar`,
                colorBy: `data`,
                barCategoryGap: `20%`,
                label: {
                    position: `inner`,
                },
                showBackground: true,
                backgroundStyle: {
                    color: `rgba(180, 180, 180, 0.2)`,
                },
            },
        ],
        grid: {
            height: `90%`,
            top: 0,
            left: 10,
            right: 10,
        },
    }

    return <ReactECharts option={options} style={{ height: `200px`, width: `100%` }} />
}
