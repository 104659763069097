/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import styled from 'styled-components'
import { Icon } from 'vspry-style-components'

const ProgressBarContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 70%;
    margin: 30px 0;
`

const ProgressLine = styled.div<{ progress: number; error?: boolean }>`
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: #c1c1c1;

    &::before {
        content: '';
        position: absolute;
        height: 4px;
        width: ${({ progress }) => progress}%;
        background-color: ${({ error, theme }) => (error ? theme.error : theme.accent)};
        transition: width 0.3s ease-in-out;
        left: 0;
    }
`

const StatusIndicator = styled.div<{ position: number; error?: boolean }>`
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${({ error, theme }) => (error ? theme.error : theme.accent)};
    border-radius: 50%;
    left: ${({ position }) => position}%;
`

const StatusIcon = styled.div<{ position: number; error?: boolean }>`
    position: absolute;
    left: ${({ position }) => position}%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ error, theme }) => (error ? theme.error : theme.accent)};
    background-color: #fff;
`

const StatusLabel = styled.div<{ position: number }>`
    position: absolute;
    top: 15px;
    left: ${({ position }) => position}%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: bold;
`

type StatusBarProps = {
    status: string
    options: string[]
    icon?: Parameters<typeof Icon>[0]['name']
}
export default function StatusBar({ status, options, icon = 'gift' }: StatusBarProps) {
    const formattedStatus = status.toLowerCase()
    const formattedOptions = options.map((option) => option.toLowerCase())

    const stepCount = formattedOptions.length
    const statusIndex = formattedOptions.indexOf(formattedStatus)

    const progressPoints = formattedOptions.map((_, index) => (index / (stepCount - 1)) * 100)
    const currentProgress = statusIndex >= 0 ? progressPoints[statusIndex] : progressPoints[progressPoints.length - 1]

    return (
        <ProgressBarContainer>
            <ProgressLine progress={currentProgress} error={statusIndex < 0} />
            {progressPoints.map((position) => (
                <StatusIndicator key={position} position={position} error={statusIndex < 0} />
            ))}
            <StatusIcon position={currentProgress} error={statusIndex < 0}>
                <Icon name={statusIndex < 0 ? 'times' : icon} size='big' fitted />
            </StatusIcon>
            <StatusLabel position={currentProgress}>{status}</StatusLabel>
        </ProgressBarContainer>
    )
}
