/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { CSSProperties, MouseEventHandler } from 'react'
import styled from 'styled-components'

import { useAuth } from 'context/authContext'

import theme from '../styles/theme'

// eslint-disable-next-line @typescript-eslint/ban-types
const Img = styled.img<{ size?: CSSProperties['width']; onClick?: Function }>`
    width: ${(props) => (props.size ? props.size : `200px`)};
    ${(props) => props.onClick && `cursor: pointer;`}
`
export default function Logo({
    size = undefined,
    onClick = undefined,
    path = undefined,
}: {
    size?: string
    onClick?: MouseEventHandler<HTMLImageElement>
    path?: string
}) {
    const { currentProduct } = useAuth()

    return <Img src={currentProduct?.issuerLogo || path || theme.logoPath} size={size} onClick={onClick} alt='' />
}
