/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React from 'react'
import styled from 'styled-components'

const StyledDivider = styled.div`
    width: 100%;
    border-bottom: 2px dashed #dddddd;
    margin: 10px 0;
`

export default function DashedDivider() {
    return <StyledDivider />
}
