/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useState } from 'react'
import { FlexBox, Text, Tile, Button, Icon, Modal, ErrorSwal, SuccessSwal, Divider } from 'vspry-style-components'

import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'

import { markOfferActivationConsumed } from 'api/merchant'
import { isHolding, OfferActivation } from 'api/common'

import { isMerchantStaff } from 'utils/config'
import { formatDateReadable } from 'utils/formatters'

import theme from 'styles/theme'

import QRCode from 'components/QRCode'
import { ThumbnailImage } from './OfferTile'
import OfferActivationStatus from './OfferActivationStatus'
import OfferDescription from './OfferDescription'

type OfferActivationTileProps = {
    activation: OfferActivation
    isSelected?: boolean
    onSelect?: () => void
    refresh: () => Promise<void>
}
export default function OfferActivationTile({ activation, isSelected, onSelect, refresh }: OfferActivationTileProps) {
    const [show, setShow] = useState(false)
    const [consuming, setConsuming] = useState(false)
    const { account, products } = useAuth()
    const { translate, translateErrorCode } = useLocale()

    const doConsume = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        if (!account) return
        setConsuming(true)
        const res = await markOfferActivationConsumed({ accountID: account.id, activationID: activation.id })
        setConsuming(false)

        if (!res?.success) {
            ErrorSwal.fire({ title: translate('swal.error.title'), text: translateErrorCode(res?.message) })
            return
        }
        SuccessSwal.fire({ title: `Activation Updated` })
        await refresh()
    }

    const useby = activation.offer.activationLastDate
        ? formatDateReadable(new Date(activation.offer.activationLastDate))
        : formatDateReadable(
              new Date(
                  new Date(activation.activatedDate).setDate(
                      new Date(activation.activatedDate).getDate() + (activation.offer.activationLastDatePeriod ?? 1)
                  )
              )
          )

    const bookingURL = activation.offer.stores?.length
        ? products.filter(isHolding).find((p) => activation.offer.stores.some((s) => s.bookingURL === p.bookingURL))?.bookingURL
        : activation.offer.bookingURL

    return (
        <>
            <Tile customBackground={`${theme.disabledButtonBG}20`} $clear $noPadding onClick={() => (onSelect ? onSelect() : null)}>
                <FlexBox $column padding='medium'>
                    <FlexBox gap='small' $align>
                        {activation.offer.imageURL && (
                            <Tile clear $noPadding width='fit-content' $noOverflow>
                                <FlexBox $align justify='center'>
                                    <ThumbnailImage src={activation.offer.imageURL} alt='offer-thumbnail' />
                                </FlexBox>
                            </Tile>
                        )}
                        <FlexBox $grow $column justify='flex-start'>
                            <Text size='small' margin='no' $bold $left>
                                {activation.offer.title}
                            </Text>
                            <Text size='xSmall' margin='no' $left>
                                {isMerchantStaff(account) ? (
                                    <>
                                        <strong>{translate('pages.offerActivation.customer')}</strong> {activation.contactName}
                                    </>
                                ) : (
                                    <>
                                        <strong>{translate('pages.offerActivation.useBy')}</strong> {useby}
                                    </>
                                )}
                            </Text>
                        </FlexBox>
                        <FlexBox gap='small' $column align='flex-end'>
                            <OfferActivationStatus status={activation.status} />
                            {isMerchantStaff(account) ? (
                                activation.status === 'Active' && (
                                    <Button id='OAT-consume-button' $small onClick={doConsume}>
                                        <Icon name={consuming ? 'circle notched' : 'shopping bag'} loading={consuming} />
                                        {translate('buttons.consumed')}
                                    </Button>
                                )
                            ) : (
                                <Button
                                    id='OAT-qr-code-button'
                                    $small
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setShow(true)
                                    }}
                                >
                                    <Icon name='qrcode' /> {translate('buttons.view')}
                                </Button>
                            )}
                        </FlexBox>
                    </FlexBox>
                    {isSelected && (
                        <>
                            <Divider $margin />
                            <FlexBox gap='small' $column>
                                <OfferDescription description={activation.offer.description} />
                                <Text size='xSmall' margin='no' $left>
                                    <strong>{translate('pages.offerActivation.activated')}</strong>{' '}
                                    {formatDateReadable(new Date(activation.activatedDate))}
                                </Text>
                                {isMerchantStaff(account) && (
                                    <Text size='xSmall' margin='no' $left>
                                        <strong>{translate('pages.offerActivation.expires')}</strong> {useby}
                                    </Text>
                                )}
                                {activation.consumedDate && (
                                    <Text size='xSmall' margin='no' $left>
                                        <strong>{translate('pages.offerActivation.consumed')}</strong>{' '}
                                        {formatDateReadable(new Date(activation.consumedDate))}
                                    </Text>
                                )}
                                {bookingURL && !isMerchantStaff(account) && (
                                    <Button id='OAT-make-booking-button' $fitted>
                                        <a
                                            onClick={(e) => e.stopPropagation()}
                                            target='_blank'
                                            rel='noreferrer'
                                            href={bookingURL}
                                            style={{ color: theme.buttonText }}
                                        >
                                            {translate('buttons.makeABooking')}
                                        </a>
                                    </Button>
                                )}
                            </FlexBox>
                        </>
                    )}
                </FlexBox>
            </Tile>
            {show && (
                <Modal open>
                    <FlexBox padding='large' $column gap='small'>
                        <QRCode
                            code={`${window.configuration['APP_URL'] || window.location.hostname}/#/marketing/activations/${activation.id}`}
                            noDownload
                        />
                        <Button id='OAT-close-modal-button' onClick={() => setShow(false)}>
                            {translate('buttons.close')}
                        </Button>
                    </FlexBox>
                </Modal>
            )}
        </>
    )
}
