/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useRef, useState } from 'react'
import { UiNode, UiNodeGroupEnum } from '@ory/client'
import { Popup } from 'semantic-ui-react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useFunctionState } from 'vspry-hooks'
import { Button, FlexBox, Icon, Loader, Text } from 'vspry-style-components'

import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'
import { useAppConfig } from 'context/appConfigContext'

import { isTenant, Type } from 'services/auth/interface'

import theme from 'styles/theme'

import VspryDivider from 'components/style-elements/VspryDivider'
import PrettyTile from 'components/PrettyTile'
import useResponsive from 'hooks/responsive'
import Node from './Node'
import KratosAuthProvider, { KratosFlow } from '../provider'
import { translateAuthCode } from './util'
import { isInputNode } from './Input'
import { isButtonNode } from './Button'

export default function Flow({ type, auth }: { type: Type; auth: KratosAuthProvider }) {
    const { appConfig } = useAppConfig()
    const [searchParams] = useSearchParams()
    const screen = useResponsive([screens.tiny])
    const location = useLocation()
    const navigate = useNavigate()
    const { translate, translateMultivalue } = useLocale()
    const { logout, identity } = useAuth()

    const methods = {
        password: translate('inputs.password'),
        webauthn: translate('inputs.webauthn'),
        passkey: translate('inputs.webauthn'),
    }
    const [{ flow, method }, setState] = useFunctionState<{ flow?: KratosFlow; method?: keyof typeof methods }>({})
    const [groups, setGroups] = useState<Record<UiNodeGroupEnum, UiNode[]> | undefined>(undefined)

    const handleMethodSelect = (m: keyof typeof methods) => {
        if (m !== 'webauthn') setState({ method: m })
    }

    useEffect(() => {
        if ('abortPasskeyConditionalUI' in window && window.abortPasskeyConditionalUI instanceof AbortController)
            window.abortPasskeyConditionalUI.abort('aborting previous prefill')
    }, [])

    const fetchFlow = () => {
        const tenant = searchParams.get('tenant')
        if (tenant && isTenant(tenant)) auth.setTenant(tenant)

        const redirect = searchParams.get('redirect') ?? undefined
        const flowID = searchParams.get('flow') ?? undefined
        auth.getFlow(type, flowID, redirect ? decodeURIComponent(redirect) : undefined).then((d) => setState({ flow: d }))
    }
    useEffect(() => {
        fetchFlow()
    }, [location])

    useEffect(() => {
        setGroups(
            flow?.ui.nodes
                .map((n) => (n.group === 'webauthn' ? { ...n, group: 'passkey' as const } : n))
                .reduce(
                    (t, c) => ({
                        ...t,
                        [c.group]: [...(t[c.group] ?? []), c],
                    }),
                    {} as Record<UiNodeGroupEnum, UiNode[]>
                )
        )
    }, [flow])

    // safety for if flow gets stuck
    const flowRef = useRef(flow)
    flowRef.current = flow
    useEffect(() => {
        const t = setTimeout(() => {
            if (!flowRef.current && !Swal.isVisible()) fetchFlow()
        }, 3000)

        return () => clearTimeout(t)
    }, [location])

    useEffect(() => {
        const e = flow?.ui.nodes.find((n) => n.messages.length > 0)
        if (groups && e && !method) setState({ method: Object.keys(groups).find((k) => e.group === k) as keyof typeof methods })
    }, [groups])

    const renderGroup = (group: UiNode[]) => (
        <FlexBox $column $fitted gap='small'>
            {group.map((n) => (
                <Node node={n} flow={type} />
            ))}
        </FlexBox>
    )

    const renderGroupHiddens = (group: UiNode[]) =>
        group
            .filter(
                (n) =>
                    (isInputNode(n) && (n.attributes.type === 'hidden' || n.attributes.name === 'webauthn_register_displayname')) ||
                    n.attributes.node_type === 'script'
            )
            .map((n) => <Node node={n} flow={type} />)

    if (!flow || !groups) return <Loader>{translate('common.fetching')}</Loader>

    if (type === 'settings')
        return (
            <FlexBox $column gap='medium'>
                <Button
                    id='back-button'
                    color='secondaryBG'
                    textColor='text'
                    width='fit-content'
                    onClick={() => {
                        navigate('/profile')
                    }}
                >
                    <Icon name='arrow left' /> {translate('buttons.back')}
                </Button>
                {'password' in groups && (
                    <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                        <PrettyTile
                            title={translate('pages.profile.loginMethods.password.title')}
                            descriptions={[translate('pages.profile.loginMethods.password.description')]}
                        >
                            <div>
                                {renderGroup(groups['password'])}
                                {renderGroup(groups['default'])}
                            </div>
                        </PrettyTile>
                    </form>
                )}
                {'passkey' in groups && (
                    <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                        <PrettyTile
                            title={translate('pages.profile.loginMethods.passkey.title')}
                            descriptions={[translate('pages.profile.loginMethods.passkey.description')]}
                        >
                            <div>
                                {renderGroup(groups['passkey'])}
                                {renderGroup(groups['default'])}
                            </div>
                        </PrettyTile>
                    </form>
                )}
                {'oidc' in groups && (
                    <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                        <PrettyTile
                            title={translate('pages.profile.loginMethods.oidc.title')}
                            descriptions={[translate('pages.profile.loginMethods.oidc.description')]}
                        >
                            <div>
                                {renderGroup(groups['oidc'])}
                                {renderGroup(groups['default'])}
                            </div>
                        </PrettyTile>
                    </form>
                )}
                {'totp' in groups && (
                    <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                        <PrettyTile
                            title={translate('pages.profile.loginMethods.totp.title')}
                            descriptions={[
                                translate('pages.profile.loginMethods.totp.description'),
                                translate('pages.profile.loginMethods.totp.description2'),
                            ]}
                        >
                            <div>
                                {renderGroup(groups['totp'])}
                                {renderGroup(groups['default'])}
                            </div>
                        </PrettyTile>
                    </form>
                )}
            </FlexBox>
        )

    return (
        <FlexBox $column $align gap='medium' width={screen > screens.tiny ? '75%' : '90%'}>
            {flow.ui.messages &&
                flow.ui.messages &&
                flow.ui.messages.map((m) => (
                    <Text size='xSmall' margin='no'>
                        {translateAuthCode(translateMultivalue, m)}
                    </Text>
                ))}
            <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                <FlexBox $column $fitted gap='small'>
                    {Object.keys(groups).length > 2 && renderGroup(groups['default'])}
                    {(type === 'recovery' || Object.keys(groups).length <= 2 || method) &&
                        Object.keys(groups)
                            .filter((k) => (type !== 'recovery' && Object.keys(groups).length > 2 ? k === method : true))
                            .map((k) => renderGroup(groups[k as keyof typeof groups]))}
                    {flow?.state === 'choose_method' && !method && Object.keys(groups).length > 2 && (
                        <FlexBox $column $fitted gap='small'>
                            {renderGroupHiddens(groups['passkey'])}
                            {Object.keys(groups)
                                .filter((k): k is keyof typeof methods => k !== 'default' && k !== 'oidc')
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((k, i) => (
                                    <Popup
                                        trigger={
                                            k === 'passkey' ? (
                                                <Node
                                                    node={
                                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                                        groups['passkey'].find((n) => isButtonNode(n))!
                                                    }
                                                    flow={type}
                                                />
                                            ) : (
                                                <Button
                                                    id={`auth-flow-method-${k}`}
                                                    onClick={() => handleMethodSelect(k)}
                                                    customColor={i > 0 ? `${theme.disabledButtonBG}40` : undefined}
                                                    color={type === 'registration' ? 'highlight' : 'buttonBG'}
                                                    textColor={i > 0 ? theme.text : ''}
                                                    name='method'
                                                    type={type !== 'registration' && k === 'webauthn' ? 'submit' : undefined}
                                                    value={k}
                                                >
                                                    {translateMultivalue('authFlows', type)} {translate('common.with')}{' '}
                                                    {methods[k as keyof typeof methods]}
                                                </Button>
                                                // eslint-disable-next-line react/jsx-indent
                                            )
                                        }
                                        on={['hover']}
                                        position='top center'
                                    >
                                        {translateMultivalue('authMethods', k as any)}
                                    </Popup>
                                ))}
                        </FlexBox>
                    )}
                </FlexBox>
            </form>
            {Object.keys(groups).includes('oidc') && (
                <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                    <VspryDivider>
                        {translateMultivalue('authFlows', type)} {translate('common.withSocials')}
                    </VspryDivider>
                    <FlexBox gap='small' $column>
                        {groups.oidc.filter(isButtonNode).map((e) => (
                            <Button
                                id={`auth-oidc-button-${e.attributes.value}`}
                                customColor={`${theme.disabledButtonBG}40`}
                                textColor={theme.text}
                                {...e.attributes}
                            >
                                <Icon name={e.attributes.value ?? ''} size='20px' /> {translateMultivalue('authFlows', type)}{' '}
                                {translate('common.with')}{' '}
                                {e.meta.label?.context && 'provider' in e.meta.label.context && typeof e.meta.label.context.provider === 'string'
                                    ? e.meta.label.context.provider
                                    : ''}
                            </Button>
                        ))}
                    </FlexBox>
                </form>
            )}
            {!(type === 'login' && !appConfig.consumers_enabled && appConfig.merchants_enabled) && (
                <Text
                    size='small'
                    $bold
                    onClick={() => {
                        if (method && Object.keys(groups).length > 2) {
                            setState({ method: undefined })
                            return
                        }
                        navigate('/')
                        if (identity) logout()
                    }}
                    margin='no'
                >
                    {translate('buttons.back')}
                </Text>
            )}
        </FlexBox>
    )
}
