/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useCallback, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { useBrowserStorage, usePagination } from 'vspry-hooks'
import { FlexBox, Icon, Text, Checkbox, Loader, Pagination } from 'vspry-style-components'

import { PaginatedResponse, Pagination as Pag } from 'api/types'
import { Holding, IDCard, OfferActivation } from 'api/common'

import { useAuth } from 'context/authContext'
import { useLocale } from 'context/localeContext'

import { isConsumer, isMerchantStaff } from 'utils/config'

import PrettyTile from 'components/PrettyTile'
import TabbedLayout from 'components/TabbedLayout'
import OfferActivationTile from './OfferActivationTile'

type OfferActivationsListByProductProps = {
    getActivations: (p: Pag) => Promise<PaginatedResponse<OfferActivation> | null>
}
function OfferActivationsListByProduct({ getActivations }: OfferActivationsListByProductProps) {
    const { translate } = useLocale()

    const [selected, setSelected] = useState('')

    const { fetching, fetchPage, page, currentPage, totalPages, refetch, items, setItems } = usePagination(getActivations, 5, 'ofpi')

    return (
        <FlexBox $column gap='small'>
            {fetching ? (
                <Loader height='250px' />
            ) : (
                <FlexBox $column gap='small'>
                    {page.length === 0 ? (
                        <FlexBox $column gap='small' height='250px' justify='center' $align>
                            <Icon name='dolly' size='huge' />
                            <Text size='small' margin='no'>
                                {translate('pages.activations.none')}
                            </Text>
                        </FlexBox>
                    ) : (
                        page.map((activation) => (
                            <OfferActivationTile
                                activation={activation}
                                isSelected={selected === activation.id}
                                onSelect={() => setSelected(selected === activation.id ? '' : activation.id)}
                                refresh={refetch}
                            />
                        ))
                    )}
                </FlexBox>
            )}
            {!!totalPages && (
                <Pagination
                    currentPage={currentPage + 1}
                    onChange={(a) => fetchPage(a - 1)}
                    totalPages={totalPages}
                    items={items}
                    setItems={setItems}
                />
            )}
        </FlexBox>
    )
}

export const productToActivationTabLabel = (p: Holding | IDCard) => `${p.product} Offers`

type OfferActivationsListProps = {
    getActivations: (p: Pag, status: string[], sort: string, cardID?: string) => Promise<PaginatedResponse<OfferActivation> | null>
}
export default function OfferActivationsList({ getActivations }: OfferActivationsListProps) {
    const { account, products, setCurrentProduct } = useAuth()
    const { translate } = useLocale()

    const [status, setStatus] = useBrowserStorage('ofst', ['Active'])
    const [sort, setSort] = useBrowserStorage('ofso', 'DESC')

    const memoizedGetPage = useCallback((p: Pag, cardID?: string) => getActivations(p, status, sort, cardID), [account, status, sort])

    return (
        <FlexBox $column gap='medium'>
            <PrettyTile
                title={translate('pages.activations.title')}
                titleRight={
                    <Dropdown trigger={<Icon name='ellipsis horizontal' color='accent' />} icon=''>
                        <Dropdown.Menu direction='left'>
                            <Dropdown.Header icon='filter' content={translate('inputs.filter')} />
                            <Dropdown.Divider />
                            <Dropdown.Item
                                onClick={() => setStatus(status.includes('Active') ? status.filter((s) => s !== 'Active') : [...status, 'Active'])}
                            >
                                <Text size='xSmall' margin='no'>
                                    <Checkbox id='OAL-active-status-check' label={translate('inputs.active')} checked={status.includes('Active')} />
                                </Text>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                    setStatus(status.includes('Consumed') ? status.filter((s) => s !== 'Consumed') : [...status, 'Consumed'])
                                }
                            >
                                <Text size='xSmall' margin='no'>
                                    <Checkbox
                                        id='OAL-completed-status-check'
                                        label={translate('inputs.completed')}
                                        checked={status.includes('Consumed')}
                                    />
                                </Text>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => setStatus(status.includes('Expired') ? status.filter((s) => s !== 'Expired') : [...status, 'Expired'])}
                            >
                                <Text size='xSmall' margin='no'>
                                    <Checkbox
                                        id='OAL-expired-status-check'
                                        label={translate('inputs.expired')}
                                        checked={status.includes('Expired')}
                                    />
                                </Text>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Header icon='sort' content={translate('inputs.sort')} />
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => setSort(sort === 'ASC' ? '' : 'ASC')}>
                                <Text size='xSmall' margin='no'>
                                    <Checkbox id='OAL-ascending-sort-check' label={translate('inputs.ascending')} checked={sort === 'ASC'} />
                                </Text>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setSort(sort === 'DESC' ? '' : 'DESC')}>
                                <Text size='xSmall' margin='no'>
                                    <Checkbox id='OAL-descending-sort-check' label={translate('inputs.descending')} checked={sort === 'DESC'} />
                                </Text>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                }
                descriptions={[
                    isConsumer(account) ? translate('pages.activations.descriptionConsumer') : translate('pages.activations.descriptionMerchant'),
                ]}
            >
                {isMerchantStaff(account) && <OfferActivationsListByProduct getActivations={(p) => memoizedGetPage(p)} />}

                {isConsumer(account) && (
                    <TabbedLayout
                        clear
                        tabs={[
                            ...products.map((product) => ({
                                label: productToActivationTabLabel(product),
                                children: <OfferActivationsListByProduct getActivations={(p) => memoizedGetPage(p, product.id)} key={product.id} />,
                            })),
                            {
                                label: 'Platform Offers',
                                children: <OfferActivationsListByProduct getActivations={(p) => memoizedGetPage(p)} key='platform-offers' />,
                            },
                        ]}
                        onChange={(t) => setCurrentProduct(products.find((p) => productToActivationTabLabel(p) === t.label) ?? null)}
                    />
                )}
            </PrettyTile>
        </FlexBox>
    )
}
