/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useState } from 'react'
import styled from 'styled-components'
import { FlexBox, Text, Tile, Icon, Button, Modal } from 'vspry-style-components'
import { useFunctionState } from 'vspry-hooks'
import { useLocale } from 'context/localeContext'
import { formatDateReadable, formatISODateTimeToInputDate } from 'utils/formatters'
import { Offer } from 'api/common'
import theme from 'styles/theme'
import DashedDivider from 'components/style-elements/DashedDivider'
import TinyTile from 'components/TinyTile'
import HeadingText from 'components/HeadingText'
import useResponsive from 'hooks/responsive'
import OfferStatistics from './OfferStatistics'
import DetailedStats from './DetailedStats'
import OfferDescription from './OfferDescription'

type OfferTileProps = {
    offer: Offer
    isSelected: boolean
    onSelect: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onEdit?: () => Promise<void | null>
    onCancel?: () => Promise<void>
    onActivate?: () => Promise<void | null>
    clear?: boolean
    transparent?: boolean
}

export const ThumbnailImage = styled.img`
    max-width: 150px;
    background-color: #ffffff;
`

const init = { cancelling: false, activating: false, linking: false }
const today = new Date().toISOString()

export default function OfferTile({ offer, isSelected, onSelect, onEdit, onCancel, onActivate, clear, transparent }: OfferTileProps) {
    const [{ cancelling, activating, linking }, setState] = useFunctionState(init)
    const [openModal, setOpenModal] = useState(false)
    const { translate } = useLocale()
    const screen = useResponsive([screens.medium])

    const doEdit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (!onEdit) return
        e.stopPropagation()
        await onEdit()
    }

    const doCancel: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (!onCancel) return
        e.stopPropagation()
        setState({ cancelling: true })
        await onCancel()
        setState({ cancelling: false })
    }

    const doActivate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, link = false) => {
        if (!onActivate) return
        e.stopPropagation()
        setState(link ? { linking: true } : { activating: true })
        await onActivate()
        setState(link ? { linking: false } : { activating: false })
    }

    if (!offer) return null

    return (
        <>
            <Tile
                customBackground={
                    (clear && transparent && `${theme.disabledButtonBG}05`) ||
                    (clear && `${theme.disabledButtonBG}20`) ||
                    (transparent && `${theme.disabledButtonBG}05`) ||
                    // eslint-disable-next-line react/jsx-indent
                    undefined
                }
                $clear={clear}
                $noPadding
                // eslint-disable-next-line no-nested-ternary
                onClick={(e) => (onSelect ? ((e.target as any).nodeName !== 'CANVAS' ? onSelect(e) : null) : null)}
            >
                <FlexBox $column padding='medium'>
                    <FlexBox gap='small' $align>
                        <FlexBox gap='small' justify='flex-start' $align>
                            {offer.imageURL && (
                                <Tile clear $noPadding width='fit-content' customBackground='#0001' $noOverflow>
                                    <FlexBox $align justify='center' height='100%'>
                                        <ThumbnailImage src={offer.imageURL} alt='offer-thumbnail' />
                                    </FlexBox>
                                </Tile>
                            )}
                            <FlexBox $grow $column justify='flex-start'>
                                <Text size='small' margin='no' $bold $left maxLines={isSelected ? undefined : 2} width='100%'>
                                    {offer.title} {!isSelected ? <Icon name='chevron down' /> : <Icon name='chevron up' />}
                                </Text>
                                <Text size='xSmall' margin='no' $left>
                                    {offer.productName ?? (
                                        <>
                                            <strong>{translate('pages.offerTile.offerExpiry.text')}</strong>{' '}
                                            {formatDateReadable(new Date(offer.expiryDate))}
                                        </>
                                    )}
                                </Text>
                                {offer.startDate && new Date(offer.startDate).toISOString() > today && (
                                    <Text $left size='xSmall'>
                                        {translate('pages.specialOffers.starts')}: {formatISODateTimeToInputDate(offer.startDate)}
                                    </Text>
                                )}
                            </FlexBox>
                        </FlexBox>
                        <FlexBox gap='small' $column>
                            {onEdit && (
                                <Button id='OT-edit-btn' customColor='transparent' $small onClick={doEdit} $noWrap>
                                    <Icon name='edit outline' color='grey' />
                                </Button>
                            )}
                            {onCancel && (
                                <Button id='OT-cancel-btn' $small customColor='transparent' onClick={doCancel} $noWrap>
                                    <Icon name={cancelling ? 'circle notched' : 'ban'} loading={cancelling} color='red' />
                                </Button>
                            )}
                        </FlexBox>
                    </FlexBox>
                    {isSelected && (
                        <>
                            <DashedDivider />
                            <FlexBox gap='small' $column>
                                <OfferDescription description={offer.description} />
                                {offer.productName && (
                                    <Text size='xSmall' margin='no' $left>
                                        <strong>{translate('pages.offerTile.offerExpiry')}</strong> {formatDateReadable(new Date(offer.expiryDate))}
                                    </Text>
                                )}
                                <Text size='xSmall' margin='no' $left>
                                    <strong>{translate('pages.offerTile.useBy')}</strong>{' '}
                                    {offer.activationLastDate
                                        ? formatDateReadable(new Date(offer.activationLastDate))
                                        : `${offer.activationLastDatePeriod} day(s)`}
                                </Text>
                                {offer.stores.length > 0 && (
                                    <Text size='xSmall' margin='no' $left>
                                        <FlexBox gap='xSmall' $wrap justify='flex-start' $align>
                                            <strong>{translate('pages.offerTile.stores')}</strong>
                                            {offer.stores.map((s) => (
                                                <TinyTile text={s.name} onRemove={undefined} />
                                            ))}
                                        </FlexBox>
                                    </Text>
                                )}
                                {onActivate && !(offer.url && offer.urlLabel) && (
                                    <Button id='OT-acivate-btn' onClick={doActivate} disabled={activating || linking} loading={activating}>
                                        {translate('buttons.activate')}
                                    </Button>
                                )}
                                {offer.url && offer.urlLabel && (
                                    <Button
                                        id='OT-doActivate-btn'
                                        onClick={(e) => {
                                            doActivate(e, true)
                                            if (offer.url) window.location.href = offer.url
                                        }}
                                        disabled={activating || linking}
                                        loading={linking}
                                    >
                                        {offer.urlLabel}
                                    </Button>
                                )}
                            </FlexBox>
                            {offer.statistics && (
                                <>
                                    <DashedDivider />
                                    <FlexBox $column gap='small'>
                                        <OfferStatistics statistics={offer.statistics} />
                                        <Button
                                            id='OT-see-more-btn'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setOpenModal(true)
                                            }}
                                            $small
                                        >
                                            {translate('pages.offerTile.seeMore')}
                                        </Button>
                                    </FlexBox>
                                </>
                            )}
                        </>
                    )}
                </FlexBox>
            </Tile>
            {openModal && (
                <Modal open={openModal} width={screen <= screens.medium ? '85vw' : '50vw'}>
                    <FlexBox $column padding='medium'>
                        <FlexBox $align padding='small'>
                            <HeadingText>{translate('pages.offerTile.statisticDetails', { title: offer.title })}</HeadingText>
                            <Icon name='times' color='black' onClick={() => setOpenModal(false)} />
                        </FlexBox>
                        <DashedDivider />
                        <DetailedStats statistics={offer.statistics} />
                    </FlexBox>
                </Modal>
            )}
        </>
    )
}
