/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { FlexBox, Text, Tile } from 'vspry-style-components'
import theme from 'styles/theme'

type Tab = {
    label: string
    children: ReactNode
}

type TabbedLayoutProps = {
    tabs: Tab[]
    heading?: boolean
    clear?: boolean
    onChange?: (active: Tab) => void
}

const TabButton = styled.button<{ active: boolean }>`
    flex: 1;
    padding: 12px;
    background: transparent;
    font-weight: bold;
    cursor: pointer;
    border: none;
    outline: none;
    border-bottom: ${({ active }) => (active ? `4px solid ${theme.accent}` : '2px solid #e0e0e0')};
    min-width: max-content;

    &:hover {
        background: #f9f9f9;
    }
`
const TabBox = styled(FlexBox)`
    width: 100%;
    flex-wrap: wrap;
`

const TabContent = styled.div`
    padding: 16px;
`

export const tabLabelToTabSearchParam = (l: string) => l.replaceAll(' ', '-').toLowerCase()

export default function TabbedLayout({ tabs, heading, clear, onChange }: TabbedLayoutProps) {
    const [searchParams, setSearchParams] = useSearchParams()

    const getTabIndexFromKey = (k: string) => tabs.findIndex((t) => tabLabelToTabSearchParam(t.label) === k)
    const getActiveTab = () => {
        const key = searchParams.get('tab')
        if (!key) return 0
        return getTabIndexFromKey(key)
    }

    const setActiveTab = (i: number) => setSearchParams({ ...searchParams, tab: tabLabelToTabSearchParam(tabs[i].label) })
    const handleChange = (i: number) => {
        setActiveTab(i)
        if (onChange) onChange(tabs[i])
    }

    const activeTab = getActiveTab()

    if (!tabs.length) return null
    return (
        <Tile $clear={clear}>
            <TabBox>
                {tabs.map((tab, index) => (
                    <TabButton key={index} active={index === activeTab} onClick={() => handleChange(index)}>
                        <Text $bold={index === activeTab} size='xSmall' margin='no' font={heading ? theme.fontAlt : theme.font}>
                            {tab.label}
                        </Text>
                    </TabButton>
                ))}
            </TabBox>
            <TabContent>{tabs[activeTab].children}</TabContent>
        </Tile>
    )
}
