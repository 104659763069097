/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import PropTypes from 'prop-types'
import { Tile, FlexBox, Text, Icon } from 'vspry-style-components'

import theme from 'styles/theme'

export default function TinyTile({ text, onRemove, ...rest }) {
    return (
        <Tile clear $noPadding width='fit-content' background={theme.disabledButtonBG} {...rest}>
            <FlexBox padding='small'>
                <Text size='xSmall' margin='no'>
                    {text} {onRemove && <Icon name='times' onClick={onRemove} fitted />}
                </Text>
            </FlexBox>
        </Tile>
    )
}

TinyTile.propTypes = {
    text: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
}

TinyTile.defaultProps = {
    onRemove: null,
}
