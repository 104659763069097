/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import styled from 'styled-components'
import { Icon } from 'vspry-style-components'

const StyledIcon = styled(Icon)`
    transition: all linear 300ms;
    transform: scaleY(300%);
    color: ${(props) => props.theme.iconButton} !important;
    cursor: pointer;
`

export default StyledIcon
