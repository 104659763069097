/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, Icon } from 'vspry-style-components'

import theme from 'styles/theme'

const NoWrapText = styled(Text)`
    white-space: nowrap;
`

export default function OfferActivationStatus({ status, size }) {
    const getStatusDeco = () => {
        switch (status) {
            case 'Active':
                return { color: theme.success, icon: 'play circle outline', background: `${theme.success}15` }
            case 'Consumed':
                return { color: theme.processing, icon: 'shopping bag', background: `${theme.processing}15` }
            default:
                return { color: theme.warning, icon: 'calendar times outline', background: `${theme.warning}15` }
        }
    }
    const statusDeco = getStatusDeco()

    return (
        <NoWrapText size={size} margin='no' color={statusDeco.color}>
            {status} <Icon name={statusDeco.icon} fitted />
        </NoWrapText>
    )
}

OfferActivationStatus.propTypes = {
    status: PropTypes.oneOf(['Active', 'Consumed', 'Expired']).isRequired,
    size: PropTypes.string,
}

OfferActivationStatus.defaultProps = {
    size: `small`,
}
