/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Option } from 'data/RecurringOptions'
import { Address } from 'utils/formatters'
import { IDCard, Message, Offer, Question } from './common'
import { enumify, GQLVariable, plutoFormMutation, plutoMutation, plutoQuery, plutoQueryQuiet, stringify } from './gqlInterface'
import { PaginatedResponse, MutationResponse, Pagination, paginationString, mutationResponseString } from './types'
import { GQLOption } from './options'

export type Activity = {
    id: string
    title: string
    description?: string
    message: string
    imgURL: string
    receive: string
    value: number
    status?: string
    type: string
    spend?: number
    externalLink?: string
    basis: string
    resetPeriod?: string
    isCovert?: boolean
    isDraft?: boolean
    isSurveyType?: boolean
    pin?: string
    priority?: string
}

export type InventoryItem = {
    id: string
    name: string
    description?: string
    quantity: number
    image?: string
    limit?: number
    redeemed?: number
}

export type Goal = {
    id: string
    title: string
    value: number
    acknowledgementMessage: string
    termsAndConditions: string
    acknowledgementSMS: boolean
    inventoryItems: InventoryItem[]
    isDraft?: boolean
}

export type MerchantRewardsProduct = {
    productID: string
    productTerms?: string
    max: number
    pointsPerStamp: number
    activities: Omit<Activity, 'message' | 'receive' | 'status' | 'priority'>[]
    goals: Goal[]
    checkinOn?: boolean
    checkoutOn?: boolean
    checkoutEstimateOn?: boolean
    surveyOn?: boolean
    surveyDelay?: number
    surveyFrequency?: number
    followUpSurveyOn: boolean
    followUpSurveyThreshold: number
    followUpSurveyUrl?: string
    cardBackground?: string
    defaultCardBackground?: string
    useDefaultCardBackground?: boolean
    cardColor?: string
    displayCardName: boolean
    manualAwardOn?: boolean
    manualAwardMax?: number
}
export const getMerchantRewardsProduct = async (accountID: string) => {
    const res = await plutoQuery<MerchantRewardsProduct>`
        merchantRewardsProduct(accountID: "${accountID}") {
            productID
            productTerms
            max
            pointsPerStamp
            activities {
                id
                type
                basis
                title
                description
                spend
                value
                externalLink
                imgURL
                resetPeriod
                isCovert
                isDraft
                isSurveyType
                pin
            }
            goals {
                id
                title
                value
                acknowledgementMessage
                termsAndConditions
                acknowledgementSMS
                inventoryItems {
                    id
                    name
                    quantity
                    description
                    image
                    limit
                }
                isDraft
            }
            checkinOn
            checkoutOn
            checkoutEstimateOn
            surveyOn
            surveyDelay
            surveyFrequency
            cardBackground
            defaultCardBackground
            useDefaultCardBackground
            cardColor
            displayCardName
            manualAwardOn
            manualAwardMax
            followUpSurveyOn
            followUpSurveyThreshold
            followUpSurveyUrl
            displayCardName
        }
    `
    return res
}

type GetMerchantClaimResponse = {
    accounts: [{ claim: Omit<Message, 'status'> }]
}
export const getMerchantClaim = async (accountID: string, claimID: string) => {
    const res = await plutoQuery<GetMerchantClaimResponse>`
        contact {
            accounts(id:"${accountID}") {
                claim(id:"${claimID}") {
                    id
                    title
                    date
                    message
                    sender
                    read
                }
            }
        }
    `
    return res?.accounts[0]?.claim ?? null
}

type GetMerchantClaimsResponse = {
    accounts: [{ claims: PaginatedResponse<Omit<Message, 'status'>> }]
}
export const getMerchantClaims = async (accountID: string, pagination: Pagination, search: string, status: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantClaimsResponse>`
        contact {
            accounts(id:"${accountID}") {
                claims(pagination: ${pagination}, search: "${search}", status: ${enumify(status)}, sort: ${sort}) {
                    set {
                        id
                        title
                        date
                        message
                        sender
                        read
                        status {
                            text
                            color
                        }
                    }
                    pagination {
                        nextPage
                        prevPage
                        total
                        pageNavigation {
                            page
                            items
                            cursor
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.claims ?? null
}

type GetMerchantUnreadClaimsResponse = { accounts: { id: string; unreadClaims: number }[] }
export const getMerchantUnreadClaims = async () => {
    const res = await plutoQueryQuiet<GetMerchantUnreadClaimsResponse>`
        contact {
            accounts {
                id
                unreadClaims
            }
        }
    `
    return res?.accounts?.reduce((t, a) => ({ ...t, [a.id]: a.unreadClaims })) ?? {}
}

type GetMerchantRedemptionsResponse = {
    accounts: [{ redemptions: PaginatedResponse<Omit<Message, 'status'>> }]
}
export const getMerchantRedemptions = async (accountID: string, pagination: Pagination, search: string, status: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantRedemptionsResponse>`
        contact {
            accounts(id:"${accountID}") {
                redemptions(pagination: ${pagination}, search: "${search}", status: ${enumify(status)}, sort: ${sort}) {
                    set {
                        id
                        title
                        date
                        message
                        sender
                        read
                        status {
                            text
                            color
                        }
                    }
                    pagination {
                        nextPage
                        prevPage
                        total
                        pageNavigation {
                            page
                            items
                            cursor
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.redemptions ?? null
}

type GetMerchantUnreadRedemptionsResponse = { accounts: { id: string; unreadRedemptions: Record<string, number> }[] }
export const getMerchantUnreadRedemptions = async () => {
    const res = await plutoQueryQuiet<GetMerchantUnreadRedemptionsResponse>`
        contact {
            accounts {
                id
                unreadRedemptions
            }
        }
    `
    return res?.accounts?.reduce((t, a) => ({ ...t, [a.id]: a.unreadRedemptions })) ?? {}
}

export const getMerchantRewardsDefaultTerms = async (accountID: string) => plutoQuery<string>`
    merchantRewardsDefaultTerms(accountID: "${accountID}")
`

type GetMerchantRewardsDefaultTriggersResponse = Pick<Activity, 'type' | 'title' | 'basis' | 'value' | 'imgURL'>[]
export const getMerchantRewardsDefaultTriggers = async (accountID: string) => plutoQuery<GetMerchantRewardsDefaultTriggersResponse>`
    merchantRewardsDefaultTriggers(accountID: "${accountID}") {
        type
        title
        basis
        value
        imgURL
    }
`

export const getMerchantRewardsDataSummary = async (accountID: string, storeID?: string) => plutoQuery<string>`
    merchantRewardsDataSummary(accountID: "${accountID}"${storeID ? `, storeID: "${storeID}"` : ''})
`

export const getMerchantRewardsCheckinSummary = async (accountID: string, storeID?: string, sinceDate?: string) => plutoQuery<string>`
    merchantRewardsCheckinSummary(accountID: "${accountID}"${storeID ? `, storeID: "${storeID}"` : ''}${
    sinceDate ? `, sinceDate: "${sinceDate}"` : ''
})
`

export const getMerchantRewardsRedemptionSummary = async (accountID: string, storeID?: string, sinceDate?: string) => plutoQuery<string>`
    merchantRewardsRedemptionSummary(accountID: "${accountID}"${storeID ? `, storeID: "${storeID}"` : ''}${
    sinceDate ? `, sinceDate: "${sinceDate}"` : ''
})
`

export const getMerchantRewardsActivationSummary = async (accountID: string, storeID?: string, sinceDate?: string) => plutoQuery<string>`
    merchantRewardsActivationSummary(accountID: "${accountID}"${storeID ? `, storeID: "${storeID}"` : ''}${
    sinceDate ? `, sinceDate: "${sinceDate}"` : ''
})
`

export const getMerchantCampaignSummary = async (accountID: string, campaignID: string) => plutoQuery<string>`
    merchantCampaignSummary(accountID: "${accountID}", campaignID: "${campaignID}")
`

export type MerchantStoreBrochure = {
    storeID: string | null
    storeName: string
    address?: Address
    brochures: {
        id: string
        name: string
        html: string
        isOrderable: boolean
    }[]
}
export const getMerchantStoreBrochures = async (accountID: string, productID: string) => plutoQuery<MerchantStoreBrochure[]>`
    merchantStoreBrochures(accountID: "${accountID}", productID: "${productID}") {
        storeID
        storeName
        brochures {
            id
            name
            html
            isOrderable
        }
    }
`

export type MerchantSocialAsset = {
    id: string
    title: string
    imgURL: string
    description?: string
}
export const getMerchantSocialAssets = async (accountID: string, productID: string, pagination: Pagination) => plutoQuery<
    PaginatedResponse<MerchantSocialAsset>
>`
        marketingSocialAssets(pagination: ${pagination}, accountID: "${accountID}", productID: "${productID}") {
            set {
                id
                title
                imgURL
                description
            }
            ${paginationString}
        }
`

export type RewardsCustomer = {
    id: string
    name: string
    email: string
    balance: number
    customTags: string[]
}
export const getMerchantRewardsCustomers = async (accountID: string, pagination: Pagination, store?: string) => {
    const res = await plutoQuery<PaginatedResponse<RewardsCustomer>>`
        merchantRewardsCustomers(accountID: "${accountID}", pagination: ${pagination}${store ? `, store: "${store}"` : ''}) {
            set {
                id
                email
                name
                balance
                customTags
            }
            ${paginationString}
        }
    `
    return res
}

type GetMerchantCustomTagsResponse = {
    accounts: [{ customTags: GQLOption[] }]
}
export const getMerchantCustomTags = async (accountID: string) => {
    const res = await plutoQuery<GetMerchantCustomTagsResponse>`
        contact {
            accounts(id:"${accountID}") {
                customTags {
                    text
                    value
                }
            }
        }
    `
    return res?.accounts[0]?.customTags.map(({ text, value }) => ({ text, value, key: value })) ?? []
}

export const createCustomerCustomTag = (input: { accountID: string; tagName: string }) =>
    plutoMutation`createCustomerCustomTag(input: ${input}) ${mutationResponseString}`
export const addCustomerCustomTag = (input: { accountID: string; customerID: string; tagID: string }) =>
    plutoMutation`addCustomerCustomTag(input: ${input}) ${mutationResponseString}`
export const removeCustomerCustomTag = (input: { accountID: string; customerID: string; tagID: string }) =>
    plutoMutation`removeCustomerCustomTag(input: ${input}) ${mutationResponseString}`

type UpdateMerchantAccountInput = {
    accountID: string
    accountName?: string
    accountTradingName?: string
    businessNumber?: string
    businessWebsite?: string
    businessIndustry?: string
    accountType?: string
    foundationDate?: string
    businessAddress?: {
        unit?: string
        number?: string
        streetName?: string
        suburb?: string
        postCode?: string
        city?: string
        state?: string
        country?: string
    }
    primaryColor?: string
    textColor?: string
    marketingSMSNotifyOn?: boolean
    force2FAOn?: boolean
    preferredLang?: string
}
export const updateMerchantAccount = async (input: UpdateMerchantAccountInput, identityImage?: File) =>
    plutoFormMutation(
        `
            mutation($identityImage: Upload) {
                updateMerchantAccount(input: ${stringify(input)}, identityImage: $identityImage) ${mutationResponseString}
            }
        `,
        identityImage ? { identityImage } : {}
    )

type GetSubscriptionSessionIDResponse = MutationResponse & { sessionID?: string }
export const getSubscriptionSessionID = async (input: { accountID: string }) => plutoMutation<GetSubscriptionSessionIDResponse>`
    createSubscriptionCustomerAndSession(input: ${input}) {
        success
        message
        sessionID
    }
`

export const getSubscriptionCustomerPortalLink = async (accountID: string) => plutoQuery<string>`
    getSubscriptionCustomerPortalLink(accountID: "${accountID}")
`

type AddStaffInput = {
    accountID: string
    firstName: string
    lastName: string
    email: string
    phone?: string
    role: string
    storeID?: string
    identityFile?: {
        classification: string
        number: string
    }
}
export const addStaff = async (input: AddStaffInput, identityFile?: File) =>
    plutoFormMutation(
        `
            mutation($identityFile: Upload) {
                addStaff(input: ${stringify(input)}, identityFile: $identityFile) ${mutationResponseString}
            }
        `,
        identityFile ? { identityFile } : {}
    )

type EditStaffInput = {
    accountID: string
    contactID: string
    role: string
    storeID?: string
    identityFile?: {
        classification: string
        number: string
    }
}
export const editStaff = async (input: EditStaffInput, identityFile?: File) =>
    plutoFormMutation(
        `
            mutation($identityFile: Upload) {
                editStaff(input: ${stringify(input)}, identityFile: $identityFile) ${mutationResponseString}
            }
        `,
        identityFile ? { identityFile } : {}
    )

type UnlinkStaffInput = {
    accountID: string
    contactID: string
}
export const unlinkStaff = async (input: UnlinkStaffInput) => plutoMutation`
    unlinkStaff(input: ${input}) {
        success
        message
    }
`

export const getIdCardQuestions = async (query = '') => plutoQuery<Question[]>`
    idCardQuestions(query: "${query}") {
        id
        question
        fields {
            id
            name
            ref
            canMultiple
            canBarcode
            children
        }
        family
        grouped
    }
`

export type IDCardDetails = {
    productID: string
    status: 'Active' | 'Inactive' | 'Pending' | 'Closure initiated'
    questions: Question[]
    cardColor?: string
    cardBackground?: string
    defaultCardBackground?: string
    useDefaultCardBackground: boolean
    cardLogo?: string
    restrictDomains: boolean
    authorisedDomains: string[]
    checkinOn: boolean
    checkoutOn: boolean
    checkoutEstimateOn: boolean
}
export const getMerchantIDCardProduct = async (accountID: string) => {
    const res = await plutoQuery<IDCardDetails>`
        merchantIDCardProduct(accountID: "${accountID}") {
            productID
            status
            questions {
                id
                question
                fields {
                    id
                    name
                    ref
                    canMultiple
                    canBarcode
                    children
                }
                customFields {
                    id
                    name
                    ref
                    canMultiple
                    canBarcode
                    children
                }
                family
                grouped
                groupTitleField {
                    id
                    name
                }
            }
            cardColor
            cardBackground
            defaultCardBackground
            useDefaultCardBackground
            cardLogo
            restrictDomains
            authorisedDomains
            checkinOn
            checkoutOn
            checkoutEstimateOn
        }
    `

    return res ?? null
}

export const getMerchantIDCard = async (accountID: string, cardID: string) => plutoQuery<IDCard>`
    merchantIDCard(accountID: "${accountID}", cardID: "${cardID}") {
        id
        asset
        assetID
        product
        productId
        cardColor
        cardBackground
        cardLogo
        questions {
            id
            question
            fields {
                id
                name
                ref
                canMultiple
                canBarcode
                children
            }
            customFields {
                id
                name
                ref
                canMultiple
                canBarcode
                children
            }
            family
            grouped
            groupTitleField {
                id
                name
            }
        }
        answers {
            id
            fieldID
            answer
            parentID
            groupID
        }
        questionHashes {
            questionID
            groupID
            hash
        }
        avatar
        firstName
        email
        startDate
        endDate
        active
    }
`
export const getMerchantIDCards = async (accountID: string, status: string | undefined, pagination: Pagination) => plutoQuery<
    PaginatedResponse<IDCard>
>`
        merchantIDCards(accountID: ${accountID}${status ? `, status: "${status}"` : ''}, pagination: ${pagination}) {
            set {
                id
                asset
                assetID
                product
                productId
                cardColor
                cardBackground
                cardLogo
                questions {
                    id
                    question
                    fields {
                        id
                        name
                        ref
                        canMultiple
                        canBarcode
                        children
                    }
                    family
                    grouped
                    groupTitleField {
                        id
                        name
                    }
                }
                answers {
                    id
                    fieldID
                    answer
                    parentID
                    groupID
                }
                questionHashes {
                    questionID
                    groupID
                    hash
                }
                avatar
                firstName
                email
                startDate
                endDate
                active
            }
            ${paginationString}
        }
`

type UpdateIDCardProductInput = {
    accountID: string
    productID?: string
    questions: string[]
    cardColor: string
    useDefaultCardBackground: boolean
    removeCardBackground: boolean
    removeCardLogo: boolean
    restrictDomains: boolean
    authorisedDomains: string[]
    checkinOn: boolean
    checkoutOn: boolean
    checkoutEstimateOn: boolean
}
export const updateIDCardProduct = async (
    input: UpdateIDCardProductInput,
    cardBackgroundFile: File | null = null,
    cardLogoFile: File | null = null
) => {
    const query = `mutation($cardBackgroundFile: Upload, $cardLogoFile: Upload) {
        updateIDCardProduct(input: ${stringify(input)}, cardBackgroundFile: $cardBackgroundFile, cardLogoFile: $cardLogoFile ) {
            success
            message
        }
    }`

    const uploadFiles = {
        ...(cardBackgroundFile && { cardBackgroundFile }),
        ...(cardLogoFile && { cardLogoFile }),
    }

    return plutoFormMutation(query, uploadFiles)
}

type AddIDCardQuestionCustomFieldsInput = {
    accountID: string
    productID: string
    questionID: string
    fields: { ref?: string; name: string }[]
}
export const addIDCardQuestionCustomFields = async (input: AddIDCardQuestionCustomFieldsInput) => plutoMutation`
    addIDCardQuestionCustomFields(input: ${input}) ${mutationResponseString}
`

type EditIDCardQuestionCustomFieldsInput = {
    accountID: string
    productID: string
    questionID: string
    fields: { ref: string; name: string }[]
}
export const editIDCardQuestionCustomFields = async (input: EditIDCardQuestionCustomFieldsInput) => plutoMutation`
    editIDCardQuestionCustomFields(input: ${input}) ${mutationResponseString}
`

type DeleteIDCardQuestionCustomFieldsInput = {
    accountID: string
    productID: string
    questionID: string
    fields: string[]
}
export const deleteIDCardQuestionCustomFields = async (input: DeleteIDCardQuestionCustomFieldsInput) => plutoMutation`
    deleteIDCardQuestionCustomFields(input: ${input}) ${mutationResponseString}
`

export interface AnswerInput {
    fieldID: string
    answer: string
    parentID?: string
    groupID?: string
}

export interface AnswerFamilyInput extends AnswerInput {
    children?: AnswerFamilyInput[]
}

type IDCardHolderInput = {
    email: string
    firstName: string
    avatar?: File
    startDate: string
    endDate?: string
    active: boolean
    answers: AnswerInput[]
    groups: AnswerInput[][]
    families: AnswerFamilyInput[]
}

type AddIDCardHoldersInput = {
    accountID: string
    productID: string
    holders: IDCardHolderInput[]
}
export const addIDCardHolders = async (input: AddIDCardHoldersInput) =>
    plutoFormMutation<MutationResponse & { holders: { email: string; cardID?: string; success: boolean; error?: string }[] }>(
        `mutation(${input.holders.map((_, i) => `$h${i}: Upload`).join(', ')}) {
            addIDCardHolders(input: ${stringify({ ...input, holders: input.holders.map((h, i) => ({ ...h, avatar: new GQLVariable(`$h${i}`) })) })}) {
                success
                message
                holders {
                    email
                    cardID
                    success
                    error
                }
            }
        }`,
        input.holders.reduce((t, c, i) => ({ ...t, [`h${i}`]: c.avatar }), {})
    )

type BulkIDCardHoldersInput = {
    accountID: string
    productID: string
}
type BulkIDCardHoldersResponse = {
    success: boolean
    message: string
    errors?: {
        reason: string
        identifier: string
        error: string
    }[]
}
export const bulkIDCardHolders = async (input: BulkIDCardHoldersInput, files: File[]) => {
    const query = `mutation($files: [Upload!]!) {
        bulkIDCardHolders(input: ${stringify(input)}, files: $files) {
            success
            message
            errors {
                reason
                identifier
                error
            }
        }
    }`
    return plutoFormMutation<BulkIDCardHoldersResponse>(query, { files })
}

type EditIDCardHolderInput = {
    accountID: string
    cardID: string
    avatar?: File
    startDate?: string
    endDate?: string
    active: boolean
}
export const editIDCardHolder = async (input: EditIDCardHolderInput) => {
    const { avatar, ...rest } = input
    const stringifiedRest = stringify(rest)
    return plutoFormMutation(
        `mutation${avatar ? '($avatar: Upload)' : ''} {
            editIDCardHolder(input: ${stringifiedRest.slice(0, -1)}${avatar ? ',avatar:$avatar}' : '}'}) {
                success
                message
            }
        }`,
        input.avatar ? { avatar: input.avatar } : {}
    )
}

type AddIDCardAnswersInput = {
    accountID: string
    cardID: string
    answers: AnswerInput[]
    groups: AnswerInput[][]
    families: AnswerFamilyInput[]
}
export const addIDCardAnswers = async (input: AddIDCardAnswersInput) => plutoMutation`
    addIDCardAnswers(input: ${input}) {
        success
        message
    }
`

export type EditIDCardAnswerInput = {
    answerID: string
    answer: string
}
type EditIDCardAnswersInput = {
    accountID: string
    cardID: string
    answers: EditIDCardAnswerInput[]
}
export const editIDCardAnswers = async (input: EditIDCardAnswersInput) => plutoMutation`
    editIDCardAnswers(input: ${input}) {
        success
        message
    }
`

type DeleteIDCardAnswerInput = {
    accountID: string
    cardID: string
    answerID: string
}
export const deleteIDCardAnswer = async (input: DeleteIDCardAnswerInput) => plutoMutation`
    deleteIDCardAnswer(input: ${input}) {
        success
        message
    }
`

type AddStoreInput = {
    accountID: string
    name: string
    phone: string
    bookingURL: string
    address: Address
    endDate?: string
}
export const addStore = async (input: AddStoreInput) => plutoMutation`
    manageStore(input: ${input}) {
        success
        message
    }
`

type EditStoreInput = {
    accountID: string
    storeID: string
    name: string
    phone: string
    bookingURL: string
    address: Address
    endDate?: string
}
export const editStore = async (input: EditStoreInput) => plutoMutation`
    manageStore(input: ${input}) {
        success
        message
    }
`

type UnlinkStoreInput = {
    accountID: string
    storeID: string
}
export const unlinkStore = async (input: UnlinkStoreInput) => plutoMutation`
    deleteStore(input: ${input}) {
        success
        message
    }
`

type ReferHealthCoverInput = {
    accountID?: string
    accountName?: string
    staffName?: string
    holdingID?: string
    firstName: string
    lastName: string
    phone: string
    email: string
    state: string
    provinceID: string
    currentHealthFund: string
    currentHealthFundID: string
    staffMember: boolean
    notes: string
}
export const referHealthCover = async (input: ReferHealthCoverInput) => plutoMutation`
    healthCoverReferral(input: ${input}) {
        success
        message
    }
`

type Checkin = {
    id: string
    date: string
    name: string
    phone: string
    store?: string
}
type GetMerchantCheckinsResponse = {
    accounts: [{ checkins: PaginatedResponse<Checkin> }]
}
export const getMerchantCheckins = async (accountID: string, pagination: Pagination, store?: string) => {
    const res = await plutoQuery<GetMerchantCheckinsResponse>`
        contact {
            accounts(id: "${accountID}") {
                checkins(pagination: ${pagination}${store ? `, store: "${store}"` : ''}) {
                    set {
                        id
                        name
                        phone
                        date
                        store
                    }
                    ${paginationString}
                }
            }
        }
    `
    return res?.accounts[0]?.checkins ?? null
}

export type Customer = {
    contactID: string
    holdingID?: string
    name: string
    longName: string
    email: string
    mobile: string
}
export const rewardsCustomerLookup = async (merchantAccountID: string, search: string) => {
    const res = await plutoQuery<Required<Customer>[]>`
    rewardsCustomerLookup(merchantAccountID: "${merchantAccountID}", search: "${search}") {
            contactID
            holdingID
            name
            longName
            email
            mobile
        }
    `
    return res?.map((c) => ({ title: c.longName, id: c.contactID, ...c })) ?? []
}
export const customerLookup = async (merchantAccountID: string, search: string) => {
    const res = await plutoQuery<Customer[]>`
        customerLookup(merchantAccountID: "${merchantAccountID}", search: "${search}") {
            contactID
            name
            longName
            email
            mobile
        }
    `
    return res?.map((c) => ({ title: c.longName, id: c.contactID, ...c })) ?? []
}
export const merchantCustomers = async (merchantAccountID: string) => {
    const res = await plutoQuery<Customer[]>`
    merchantCustomers(merchantAccountID: "${merchantAccountID}") {
            contactID
            name
            longName
            email
            mobile
        }
    `
    return res?.map((c) => ({ title: c.longName, id: c.contactID, ...c })) ?? []
}

type MerchantCheckinInput = {
    merchantAccountID: string
    contactID: string
    holdingID: string
    storeID: string
}
export const merchantCheckin = async (input: MerchantCheckinInput) => plutoMutation`
    merchantCheckin(input: ${input}) {
        success
        message
    }
`

type MerchantManualAwardInput = {
    accountID: string
    holdingID: string
    consumerHoldingID: string
    amount: number
    description?: string
}
export const manualAward = async (input: MerchantManualAwardInput) => plutoMutation`
    manualAward(input: ${input}) {
        success
        message
    }
`

export type Store = {
    id: string
    name: string
    phone?: string
    bookingURL?: string
    address?: Address
}
export type Campaign = {
    id: string
    status: string
    name: string
    start: string
    end: string
    enableSMS: boolean
    offers: Offer[]
}
type GetMerchantCampaignsResponse = {
    accounts: [{ campaigns: PaginatedResponse<Campaign> }]
}
export const getMerchantCampaigns = async (accountID: string, pagination: Pagination, statuses: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantCampaignsResponse>`
        contact {
            accounts(id: "${accountID}") {
                campaigns(pagination: ${pagination}, status: ${enumify(statuses)}, sort: ${sort}) {
                    set {
                        id
                        status
                        name
                        start
                        end
                        enableSMS
                        offers {
                            id
                            campaignID
                            title
                            description
                            expiryDate
                            startDate
                            activationLastDate
                            activationLastDatePeriod
                            stores {
                                id
                                name
                            }
                            maxActivations
                            audience
                            imageURL
                            productName
                            productID
                            statistics {
                                prospects
                                clicks {
                                    name
                                }
                                activations {
                                    name
                                }
                                redemptions {
                                    name
                                }
                            }
                            excludedTags
                            excludedSegments
                            excludedCustom
                            excludedContacts {
                                contactID
                                name
                                email
                                mobile
                                longName
                            }
                            includedTags
                            includedSegments
                            includedCustom
                            includedContacts {
                                contactID
                                name
                                email
                                mobile
                                longName
                            }
                        }
                    }
                    ${paginationString}
                }
            }
        }
    `
    return res?.accounts[0]?.campaigns ?? null
}

type GetMerchantCampaignResponse = {
    accounts: [{ campaign: Campaign }]
}
export const getMerchantCampaign = async (accountID: string, campaignID: string) => {
    const res = await plutoQuery<GetMerchantCampaignResponse>`
        contact {
            accounts(id: "${accountID}") {
                campaign(id: "${campaignID}") {
                    id
                    status
                    name
                    start
                    end
                    enableSMS
                    offers {
                        id
                        campaignID
                        title
                        description
                        expiryDate
                        startDate
                        activationLastDate
                        activationLastDatePeriod
                        stores {
                            id
                            name
                        }
                        maxActivations
                        audience
                        imageURL
                        productName
                        productID
                        excludedTags
                        excludedSegments
                        excludedCustom
                        excludedContacts {
                            contactID
                            name
                            email
                            mobile
                            longName
                        }
                        includedTags
                        includedSegments
                        includedCustom
                        includedContacts {
                            contactID
                            name
                            email
                            mobile
                            longName
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.campaign ?? null
}

export type CampaignInput = {
    name: string
    start: string
    end: string
    enableSMS?: boolean
}

type AddCampaignInput = {
    accountID: string
    campaign: CampaignInput
}
type AddCampaignResponse = MutationResponse & { campaignID?: string }
export const addCampaign = async (input: AddCampaignInput) => plutoMutation<AddCampaignResponse>`
    createCampaign(input: ${stringify(input)}) {
        success
        message
        campaignID
    }
`

type EditCampaignInput = {
    accountID: string
    campaignID: string
    campaign: CampaignInput
}
export const editCampaign = async (input: EditCampaignInput) => plutoMutation`
    editCampaign(input: ${stringify(input)}) {
        success
        message
    }
`

type CancelCampaignInput = {
    campaignID: string
    accountID: string
}
export const cancelCampaign = async (input: CancelCampaignInput) => plutoMutation`
    cancelCampaign(input: ${input}) {
        success
        message
    }
`

type ArchiveCampaign = {
    campaignID: string
    accountID: string
}

export const archiveCampaign = async (input: ArchiveCampaign) => plutoMutation`
    archiveCampaign(input: ${input}) {
        success
        message
    }
`

type DeleteCampaign = {
    campaignID: string
    accountID: string
}

export const deleteCampaign = async (input: DeleteCampaign) => plutoMutation`
    deleteCampaign(input: ${input}) {
        success
        message
    }
`

export type OfferInput = {
    title: string
    description: string
    expiryDate: string
    startDate?: string
    activationLastDate?: string
    activationLastDatePeriod?: number
    stores: string[]
    maxActivations?: number
    audience?: number
    image?: File
    productID?: string
    excludedTags: string[]
    excludedSegments: string[]
    excludedCustom: string[]
    excludedContacts: string[]
    includedTags: string[]
    includedSegments: string[]
    includedCustom: string[]
    includedContacts: string[]
}

type CreateOfferInput = {
    accountID: string
    campaignID: string
    offer: OfferInput
}
type CreateOfferResponse = MutationResponse & { offerID?: string }
export const createOffer = async (input: CreateOfferInput) =>
    plutoFormMutation<CreateOfferResponse>(
        `
            mutation($image: Upload, $description: String!) {
                createOffer(input: ${stringify({
                    ...input,
                    offer: { ...input.offer, image: new GQLVariable('$image'), description: new GQLVariable('$description') },
                })}) {
                    success
                    message
                    offerID
                }
            }
        `,
        { description: input.offer.description, ...(input.offer.image ? { image: input.offer.image } : {}) }
    )

type EditOfferInput = {
    accountID: string
    offerID: string
    offer: OfferInput
}
export const editOffer = async (input: EditOfferInput) =>
    plutoFormMutation(
        `
            mutation($image: Upload, $description: String!) {
                editOffer(input: ${stringify({
                    ...input,
                    offer: { ...input.offer, image: new GQLVariable('$image'), description: new GQLVariable('$description') },
                })}) {
                    success
                    message
                }
            }
        `,
        { description: input.offer.description, ...(input.offer.image ? { image: input.offer.image } : {}) }
    )

type GetMerchantOfferEnabledProductsResponse = {
    accounts: [{ offerEnabledProducts: Omit<Option, 'key'>[] }]
}
export const getMerchantOfferEnabledProducts = async (accountID: string) => {
    const res = await plutoQuery<GetMerchantOfferEnabledProductsResponse>`
        contact {
            accounts(id: "${accountID}") {
                offerEnabledProducts {
                    text
                    value
                }
            }
        }
    `
    return res?.accounts[0]?.offerEnabledProducts ?? []
}

export const markOfferActivationConsumed = async (input: { accountID: string; activationID: string }) => plutoMutation`
    consumeOfferActivation(input: ${input}) {
        success
        message
    }
`

type MarketingEmailTemplate = {
    id: string
    name: string
    content: string
    plain: string
}
export const getMerchantEmailTemplates = async (accountID: string, productID: string, pagination: Pagination, storeID: string) => plutoQuery<
    PaginatedResponse<MarketingEmailTemplate>
>`
        marketingEmailTemplates(pagination: ${pagination}, accountID: "${accountID}", productID: "${productID}", store: "${storeID}") {
            set {
                id
                name
                content
                plain
            }
            ${paginationString}
        }
`

type MarketingSMSTemplate = {
    id: string
    name: string
    plain: string
}
export const getMerchantSMSTemplates = async (accountID: string, productID: string, pagination: Pagination, storeID: string) => plutoQuery<
    PaginatedResponse<MarketingSMSTemplate>
>`
        marketingSMSTemplates(pagination: ${pagination}, accountID: "${accountID}", productID: "${productID}", store: "${storeID}") {
            set {
                id
                name
                plain
            }
            ${paginationString}
        }
`

export type BrochurePurchaseOption = {
    id: string
    name: string
    description?: string
    price: number
}
export const getMerchantBrochurePurchaseOptions = async (accountID: string) => plutoQuery<BrochurePurchaseOption[]>`
    merchantBrochurePurchaseOptions(accountID: "${accountID}") { id name description price }
`

type CreateMerchantAccountInput = {
    businessName: string
    tradingName?: string
    governmentID?: string
    trustType?: string
    trusteeFirstName?: string
    trusteeMiddleNames?: string
    trusteeLastName?: string
    trusteeDOB?: string
    trusteeAddress?: Address
    trusteeRegistration?: string
    trusteeLegalName?: string
    firstName: string
    middleNames?: string
    lastName?: string
    lastNameSecond?: string
    suffix?: string
    dob?: string
    phone: string
    address?: Address
    email: string
    position?: string
    businessLine?: string
    founded?: string
    businessType?: string
    entityType?: string
    businessAddress?: Address
    acceptedTerms: string[]
    preferredLang: string
    dynamicInputs: Record<string, string | Record<string, string>>
}
export const createMerchantAccount = async (input: CreateMerchantAccountInput, dynamicFiles: File[]) =>
    plutoFormMutation(
        `
            mutation($dynamicFiles: [Upload!]!) {
                createMerchantAccount(input: ${stringify(input)}, dynamicFiles: $dynamicFiles) ${mutationResponseString}
            }
        `,
        { dynamicFiles }
    )

export type DealOffer = {
    id: string
    lender: string
    lenderID: string
    status: 'Draft' | 'Issued' | 'Viewed' | 'Declined' | 'Accepted' | 'Lapsed' | 'Completed'
    amount: number
    currency: string
    term: number
    structure?: string
    balloon?: number
    securityType: string
    expiry?: string
    conditions: string[]
    fees: number
    repaymentAmount: number
    repaymentNumber: number
    repaymentFrequency: 'Monthly' | 'Fortnightly'
}
export type DealLender = {
    id: string
    status: 'Matched' | 'Accepted' | 'Declined'
    estimatedOfferDays?: number
}
export type Deal = {
    id: string
    usage: string
    usageName: string
    usageImage: string
    creationDate: string
    requiredDate?: string
    displayStatus: 'Draft' | 'In progress' | 'Offers available' | 'Offers lapsed' | 'Completed' | 'Closed'
    status?: string
    amount: number
    term: number
    balloon?: number
    offers: PaginatedResponse<DealOffer>
    lenders: DealLender[]
}
type GetMerchantDealsResponse = {
    accounts: [{ deals: PaginatedResponse<Deal> }]
}
export const getMerchantDeals = async (accountID: string, pagination: Pagination, statuses: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantDealsResponse>`
        contact {
            accounts(id: "${accountID}") {
                deals(pagination: ${pagination}, status: ${statuses.map((v) => `"${v}"`)}, sort: ${sort}) {
                    set {
                        id
                        usage
                        usageName
                        usageImage
                        creationDate
                        requiredDate
                        displayStatus
                        status
                        amount
                        term
                        balloon
                        offers(pagination: {limit:50}) {
                            set {
                                id
                                lender
                                lenderID
                                status
                                amount
                                currency
                                term
                                structure
                                balloon
                                securityType
                                expiry
                                conditions
                            }
                            ${paginationString}
                        }
                        lenders {
                            id
                            status
                            estimatedOfferDays
                        }
                    }
                    ${paginationString}
                }
            }
        }
    `
    return res?.accounts[0]?.deals ?? null
}

type GetMerchantDealResponse = {
    accounts: [{ deal: Deal }]
}
export const getMerchantDeal = async (accountID: string, dealID: string, pagination: Pagination) => {
    const res = await plutoQuery<GetMerchantDealResponse>`
        contact {
            accounts(id: "${accountID}") {
                deal(id: "${dealID}") {
                    id
                    usage
                    usageName
                    usageImage
                    creationDate
                    requiredDate
                    displayStatus
                    status
                    amount
                    term
                    balloon
                    offers(pagination: ${pagination}) {
                        set {
                            id
                            lender
                            lenderID
                            status
                            amount
                            currency
                            term
                            structure
                            balloon
                            securityType
                            expiry
                            conditions
                            fees
                            repaymentAmount
                            repaymentNumber
                            repaymentFrequency
                        }
                        ${paginationString}
                    }
                    lenders {
                        id
                        status
                        estimatedOfferDays
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.deal ?? null
}

type UpdateMerchantDealOfferStatusInput = {
    accountID: string
    dealOfferID: string
    status: DealOffer['status']
}
export const updateMerchantDealOfferStatus = async (input: UpdateMerchantDealOfferStatusInput) =>
    plutoMutation`updateDealOfferStatus(input: { accountID: "${input.accountID}", dealOfferID: "${input.dealOfferID}", status: ${input.status}}) ${mutationResponseString}`

type UpdateTransactionMetaInput = {
    accountID: string
    holdingID: string
    transactionID: string
    invoiceRef?: string
}
export const updateTransactionMeta = async (input: UpdateTransactionMetaInput, invoiceFile?: File) =>
    plutoFormMutation(
        `
            mutation($invoiceFile: Upload) {
                updateTransactionMeta(input: ${stringify(input)}, invoiceFile: $invoiceFile) ${mutationResponseString}
            }
        `,
        invoiceFile ? { invoiceFile } : {}
    )

type SendCustomerMessageInput = {
    accountID: string
    contacts: string[]
    subject: string
    message: string
}
export const sendCustomerMessage = async (input: SendCustomerMessageInput) =>
    plutoMutation`sendCustomerMessage(input: ${input}) ${mutationResponseString}`

type GetMerchantSentMessageResponse = {
    accounts: [{ sentMessage: Message }]
}
export const getMerchantSentMessage = async (accountID: string, messageID: string) => {
    const res = await plutoQuery<GetMerchantSentMessageResponse>`
        contact {
            accounts(id:"${accountID}") {
                sentMessage(id:"${messageID}") {
                    id
                    title
                    date
                    message
                    receiver
                    sender
                    read
                }
            }
        }
    `
    return res?.accounts[0].sentMessage ?? null
}

type GetMerchantSentMessagesResponse = {
    accounts: [{ sentMessages: PaginatedResponse<Message> }]
}
export const getMerchantSentMessages = async (accountID: string, pagination: Pagination, search?: string) => {
    const res = await plutoQuery<GetMerchantSentMessagesResponse>`
        contact {
            accounts(id:"${accountID}") {
                sentMessages(pagination: ${pagination}, search: "${search}") {
                    set {
                        id
                        title
                        date
                        message
                        receiver
                        sender
                        read
                    }
                    pagination {
                        nextPage
                        prevPage
                        total
                        pageNavigation {
                            page
                            items
                            cursor
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0].sentMessages ?? null
}
