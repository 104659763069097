/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { createGlobalStyle } from 'styled-components'

import theme from './theme'

const GlobalStyle = createGlobalStyle`
body {
	font-family: ${theme.font};
	height: initial !important;
}

button {
	font-family: ${theme.font};
}

.ui.icon.input > input {
	padding-right: 0 !important;
}

.ui.icon.button > .icon,
.ui.icon.buttons .button > .icon {
	margin: auto !important;
}

// Additional
.ui.button.standard {
	font-size: 16px;
	width: 240px;
}

.ui.button.standard-small {
	font-size: 12px;
	width: 200px;
}

.ui.button.social {
	font-size: 16px;
	border-radius: 100px;
}

.ui.buttons {
	&.small {
		.ui.button:not(.no-styling) {
			height: 36px;
		}
	}
	.ui.button:not(.no-styling) {
		background: rgba(255, 255, 255, 0.5);
		&.active {
			background: #FFFFFF;
		}
	}
}

.ui.buttons .button:first-child {
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.ui.buttons .button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
}

.ui.button:not(.no-styling) {
	font-size: 16px;
	border-radius: 100px;
	background: #FFFFFF;
	color: #000;
	width: 240px; // Additional
	-webkit-appearance: none;
	&.link {
		background: transparent;
		color: #FFFFFF;
	}
	&.size-200 {
		width: 200px !important;
	}
	&.size-40 {
		width: auto !important;
	}
}

// identity provider
.smileui-container {
	background-color: rgba(0, 0, 0, 0.5);
}

.message-button {
	border: none;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
	color: ${theme.buttonText};
	background: ${theme.buttonBG};
	display: block;
	width: 100%;
	margin: 0px auto;

    :hover {
        background: ${theme.buttonBG}c8;
		color: ${theme.buttonText};
        cursor: pointer;
    }
}

.message-button-gray {
	background: ${theme.disabledButtonBG};
}
.message-button-red {
	background: ${theme.error};
}

.tox-notifications-container {
	display: none !important;
}

.trix-editor {
	text-align: left;
	min-height: 200px;
	max-height: 300px;
	overflow-y: auto;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: ${theme.disabledButtonBG};
  border-radius: 0.5rem;
  height: 0.5rem;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -4px;
  background-color: ${theme.accent};
  border-radius: 0.5rem;
  height: 1rem;
  width: 2rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 3px solid ${theme.accent};
  outline-offset: 0.125rem;
}

input[type="range"]::-moz-range-track {
  background-color: ${theme.disabledButtonBG};
  border-radius: 0.5rem;
  height: 0.5rem;
}

input[type="range"]::-moz-range-thumb {
  background-color: ${theme.accent};
  border: none;
  border-radius: 0.5rem;
  height: 1rem;
  width: 2rem;
}

input[type="range"]:focus::-moz-range-thumb{
  outline: 3px solid ${theme.accent};
  outline-offset: 0.125rem;
}

trix-toolbar .trix-button-group:not(:first-child) {
    margin-left: 0vw !important;
}

trix-toolbar .trix-button--icon {
	max-width: auto !important;
}

.swiper-cards .swiper-slide {
	overflow: visible !important
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}
`

export default GlobalStyle
