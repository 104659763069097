/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { UiNode, UiNodeInputAttributes } from '@ory/client'
import { Icon, Button as StyledButton } from 'vspry-style-components'
import { useBrowserStorage } from 'vspry-hooks'

import { useLocale } from 'context/localeContext'

import { Type } from 'services/auth/interface'

import { translateAuthCode } from './util'

type ButtonNode = Omit<UiNode, 'attributes'> & {
    attributes: { node_type: 'input'; type: 'button' | 'submit' } & UiNodeInputAttributes
} & { flow: Type }
export const isButtonNode = (a: UiNode): a is ButtonNode => {
    if (a.attributes.node_type === 'input' && (a.attributes.type === 'button' || a.attributes.type === 'submit')) return true
    return false
}

export default function Button({ attributes, meta, group, flow }: ButtonNode) {
    const { translateMultivalue, translate } = useLocale()
    const [usedPasskey, setUsedPasskey] = useBrowserStorage('auth-used-passkey', false)

    const callWebauthnFunction = (functionBody: string) => {
        const actualFuncBody =
            functionBody === 'window.oryPasskeyLoginAutocompleteInit()'
                ? `window.oryPasskeyLoginAutocompleteInit(${usedPasskey ? `"optional"` : ''})`
                : functionBody
        // eslint-disable-next-line no-new-func
        const run = new Function(actualFuncBody)

        const intervalHandle = window.setInterval(() => {
            // eslint-disable-next-line no-underscore-dangle
            if ((window as any).__oryWebAuthnInitialized) {
                run()
                window.clearInterval(intervalHandle)
            }
        }, 100)

        return intervalHandle
    }

    const onClick = (e: React.MouseEvent | React.FormEvent<HTMLFormElement>) => {
        if (group === 'passkey' && attributes.name === 'passkey_remove') setUsedPasskey(false)
        if (attributes.onclick) {
            e.stopPropagation()
            e.preventDefault()
            callWebauthnFunction(attributes.onclick)
        }
    }

    useEffect(() => {
        if (attributes.onload) callWebauthnFunction(attributes.onload)
    }, [])

    useEffect(() => {
        if (attributes.name === 'webauthn_login_trigger' && attributes.onclick) callWebauthnFunction(attributes.onclick)
    }, [])

    return (
        <StyledButton
            {...attributes}
            id={`auth-input-${attributes.name}`}
            onClick={onClick}
            $fitted
            color={meta.label?.text === 'Sign up' || flow === 'registration' ? 'highlight' : 'buttonBG'}
        >
            {group === 'oidc' && (
                <>
                    <Icon name={attributes.value ?? ''} size='20px' />{' '}
                </>
            )}
            {flow === 'verification' && meta.label?.id === 1070009
                ? translate('pages.verification.resend')
                : translateAuthCode(translateMultivalue, meta.label)}
        </StyledButton>
    )
}
