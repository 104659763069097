/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ReactNode } from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { Text } from 'vspry-style-components'

const TileContainer = styled.div`
    border: 1px solid #e3e6e4;
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
`

const ShortView = styled.div<{ isExpanded: boolean }>`
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ isExpanded, theme }) => (isExpanded ? `${theme.accent}50` : '#ffffff')};
    border-radius: 7px;
    transition: background-color 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
`

const ExpandedContent = styled.div`
    padding: 20px;
    background: white;
    border-top: 1px solid #d1d5db;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`

type ExpandableTileProps = {
    title: string
    status: string
    children: ReactNode
    isExpanded: boolean
    onToggle: () => void
}

export default function ExpandableTile({ title, status, children, isExpanded, onToggle }: ExpandableTileProps) {
    return (
        <TileContainer onClick={onToggle}>
            <ShortView isExpanded={isExpanded}>
                <Text $bold $left size='xSmall' margin='no'>
                    {title}
                </Text>

                <Text size='xSmall' margin='no'>
                    {status} <Icon name={isExpanded ? 'chevron up' : 'chevron down'} />
                </Text>
            </ShortView>

            {isExpanded && <ExpandedContent>{children}</ExpandedContent>}
        </TileContainer>
    )
}
