/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

/* eslint-disable i18next/no-literal-string */

import moment from 'moment'

// const moneyOptions = { style: 'currency', currency: 'AUD', currencyDisplay: 'symbol', maximumFractionDigits: 0 }
// TODO: make the conversion come from locale
export const formatCurrency = (num: string | number, symbol: string, multiplier = 100) => {
    if (Number.isNaN(Number(num))) return 'NaN'
    if (Number(num) < 0)
        return `-${symbol}${Math.abs(Number(num) / multiplier)
            .toFixed(String(multiplier).length - 1)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
    return `${symbol}${(Number(num) / multiplier).toFixed(String(multiplier).length - 1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}

// TODO: make the conversion come from locale
export const formatCurrencyField = (num: string | number, multiplier = 100) => Number(num) * multiplier

export const formatCurrencyValue = (num: string | number, multiplier = 100) => {
    const whole = Number(num) / multiplier
    return whole.toLocaleString()
}

// creates a human readable date in the format of Apr 1st 2020
export const formatDateReadable = (date: Date, format: 'MMMDDYYYY' | 'MMDDYYYY' | 'DDMMYYYY' = 'MMMDDYYYY') => {
    switch (format) {
        case 'DDMMYYYY':
            return moment(date).format('DD/MM/YYYY')
        case 'MMDDYYYY':
            return moment(date).format('MM/DD/YYYY')
        case 'MMMDDYYYY':
            return moment(date).format('MMM Do YYYY')
        default:
            return moment(date).format('MMM Do YYYY')
    }
}

// creates a human readable date in the format of Apr 1st 2020
export const formatDateTimeReadable = (date: Date, format: 'MMMDDYYYY' | 'MMDDYYYY' | 'DDMMYYYY' = 'MMMDDYYYY') => {
    switch (format) {
        case 'DDMMYYYY':
            return moment(date).format('DD/MM/YYYY h:mm a')
        case 'MMDDYYYY':
            return moment(date).format('MM/DD/YYYY h:mm a')
        case 'MMMDDYYYY':
            return moment(date).format('MMM Do YYYY h:mm a')
        default:
            return moment(date).format('MMM Do YYYY h:mm a')
    }
}

export const formatISODateTimeToInputDateTime = (date: string) => moment(new Date(date)).format('YYYY-MM-DD[T]HH:mm')
export const formatISODateTimeToInputDate = (date: string) => moment(new Date(date)).format('YYYY-MM-DD')
export const formatInputDateTimeToISODateTime = (date: string) => new Date(date).toISOString()

export const formatCardNumber = (cardNumber: string) => {
    const cleanCardNumber = cardNumber.replace(/\s/gm, '')
    let formattedNumber = ''
    for (let i = 0; i < cleanCardNumber.length; i += 4) {
        if (i + 4 > cleanCardNumber.length) formattedNumber += cleanCardNumber.substring(i)
        if (i + 4 === cleanCardNumber.length) formattedNumber += cleanCardNumber.substring(i, i + 4)
        else formattedNumber += `${cleanCardNumber.substring(i, i + 4)} `
    }
    return formattedNumber
}

export const formatGovCardNumber = (cardNumber: string) => {
    const array = [cardNumber.slice(0, 2), cardNumber.slice(2, 11), cardNumber.slice(11)]
    return array.join('-')
}

export const hideCardNumber = (cardNumber: string) => {
    const cleanCardNumber = cardNumber.replace(/\s/gm, '')
    let formattedNumber = ''
    for (let i = 0; i < cleanCardNumber.length - 4; i++) {
        formattedNumber += '*'
    }
    formattedNumber += cleanCardNumber.substring(cleanCardNumber.length - 4)
    return formatCardNumber(formattedNumber)
}

const checkName = (name?: string) => (name ? `${name} ` : '')

const abbreviateName = (name?: string) => {
    if (!name) return ''
    const names = name.split(' ')
    let abbrName = ''
    names.forEach((n) => {
        abbrName += `${n.substring(0, 1)} `
    })
    return abbrName
}

export const formatNameToMaxLength = (
    person: { title?: string; firstName: string; middleName?: string; lastName: string; suffix?: string },
    maxLength = Infinity
) => {
    const { title, firstName, middleName, lastName, suffix } = person
    let name = `${checkName(title)}${checkName(firstName)}${checkName(middleName)}${checkName(lastName)}${checkName(suffix)}`
    if (name.length > maxLength)
        name = `${checkName(title)}${checkName(firstName)}${abbreviateName(middleName)}${checkName(lastName)}${checkName(suffix)}`
    if (name.length > maxLength)
        name = `${checkName(title)}${abbreviateName(firstName)}${abbreviateName(middleName)}${checkName(lastName)}${checkName(suffix)}`
    if (name.length > maxLength) name = `${checkName(title)}${abbreviateName(firstName)}${checkName(lastName)}${checkName(suffix)}`
    if (name.length > maxLength) name = `${checkName(title)}${abbreviateName(firstName)}${abbreviateName(lastName)}${checkName(suffix)}`
    return name
}

export const formatObjectToEmptyStringShape = <T extends object>(shape: T, object: Partial<T>): T => {
    const formattedObject: any = {}
    const keys = Object.keys(shape)
    keys.forEach((k) => {
        formattedObject[k] = object[k as keyof typeof object] ?? ''
    })
    return formattedObject
}

const capitalizeFirstLetter = (string: string) => `${string.charAt(0).toUpperCase()}${string.substring(1).toLowerCase()}`

export const formatCapitalCase = (string: string) => {
    const strings = string.split(' ')
    return strings.reduce((t, c, i) => (i === 0 ? capitalizeFirstLetter(c) : `${t} ${capitalizeFirstLetter(c)}`), '')
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const b64EncodeUnicode = (str: string) => btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode(`0x${p1}`)))

export const b64DecodeUnicode = (str: string) =>
    decodeURIComponent(Array.prototype.map.call(atob(str), (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''))

export type Address = {
    unit?: string
    number?: string
    streetName?: string
    suburb?: string
    city?: string
    state?: string
    postCode?: string
    country?: string
    geo?: { lat: number; long: number }
}
export const formatAddress = ({ unit, number, streetName, suburb, city, state, postCode, country }: Address) =>
    `${unit ? `${unit}/` : ''}${number ? `${number} ` : ''}${streetName ? `${streetName} ` : ''}${suburb ? `${suburb}, ` : ''}${
        postCode ? `${postCode}, ` : ''
    }${city ? `${city}, ` : ''}${state ? `${state}, ` : ''}${country ? `${country}` : ''}`

export const trimURL = (v: string | undefined) => {
    if (!v) return v
    if (v.startsWith('http://') || v.startsWith('https://')) return v.split('://').slice(1).join('://')
    return v
}

export const formatColor = (v: string) => {
    // remove hash for processing
    let f = v.replace('#', '')

    // uppercase everything
    f = f.toUpperCase()

    // turn shorthand to long
    if (f.length === 3 || f.length === 4) {
        f = [...f].map((e) => `${e}${e}`).join('')
    }

    // add hash back and return
    return `#${f}`
}
