/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Offer } from 'api/common'
import { useLocale } from 'context/localeContext'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { FlexBox, Icon, Text } from 'vspry-style-components'

type DetailedStatsProps = {
    statistics: Offer['statistics']
}

const TitleItem = styled.div`
    width: 25%;
`

const ProgressBar = styled.div`
    flex: 1;
    display: flex;
    height: 20px;
    background-color: #eee;
    border-radius: 7px;

    & > div {
        background-color: ${(props) => props.theme.accent || 'green'};
        transition: width 0.3s ease-in-out;
        border-radius: 20px;
    }
`

export default function DetailedStats({ statistics }: DetailedStatsProps) {
    const { translate } = useLocale()
    const tableTitle: string[] = [
        `${translate('pages.detailedStats.user')}`,
        `${translate('pages.detailedStats.clicked')}`,
        `${translate('pages.detailedStats.activated')}`,
        `${translate('pages.detailedStats.redeemed')}`,
    ]

    const clickedUsers = statistics?.clicks || []
    const activatedUsers = statistics?.activations || []
    const redeemedUsers = statistics?.redemptions || []

    // combine all users listed in cliked, activated, and redeemed actions
    const allUsers = useMemo(() => {
        const combinedUsers = new Set<string>()

        clickedUsers.forEach((user) => combinedUsers.add(user.name))
        activatedUsers.forEach((user) => combinedUsers.add(user.name))
        redeemedUsers.forEach((user) => combinedUsers.add(user.name))

        const combinedArray = Array.from(combinedUsers).map((name) => {
            let status = 'Clicked'
            if (activatedUsers.some((user) => user.name === name)) {
                status = 'Activated'
            }
            if (redeemedUsers.some((user) => user.name === name)) {
                status = 'Redeemed'
            }
            return { name, status }
        })
        return combinedArray
    }, [clickedUsers, activatedUsers, redeemedUsers])

    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10

    // paginate modal
    const paginatedUsers = useMemo(() => {
        const startingIndex = (currentPage - 1) * itemsPerPage
        const pagination = allUsers.slice(startingIndex, startingIndex + itemsPerPage)
        return pagination
    }, [allUsers, currentPage])

    const totalPages = Math.ceil(allUsers.length / itemsPerPage)

    // get progress bar width based on status of users action
    const getProgressWidth = (status: string) => {
        switch (status) {
            case 'Clicked':
                return '33%'
            case 'Activated':
                return '66%'
            case 'Redeemed':
                return '100%'
            default:
                return '0%'
        }
    }

    return allUsers.length === 0 ? (
        <Text size='small'>{translate('pages.detailedStats.noCurrentData')}</Text>
    ) : (
        <>
            <FlexBox>
                {tableTitle.map((t) => (
                    <TitleItem>
                        <Text margin='no' size='xSmall' $left $bold>
                            {t}
                        </Text>
                    </TitleItem>
                ))}
            </FlexBox>
            <div style={{ marginTop: '5px' }}>
                {paginatedUsers.map((user) => (
                    <FlexBox $align>
                        <Text $left width='23%' size='small'>
                            {user.name}
                        </Text>
                        <ProgressBar>
                            <div style={{ width: getProgressWidth(user.status) }} />
                        </ProgressBar>
                    </FlexBox>
                ))}
                <FlexBox justify='end' $align margin='medium' gap='small'>
                    {currentPage > 1 && <Icon name='arrow left' onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} />}
                    <span>
                        {translate('pages.detailedStats.pageProgress', { firstNumber: currentPage.toString(), totalNumber: totalPages.toString() })}
                    </span>
                    {currentPage < totalPages && <Icon name='arrow right' onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} />}
                </FlexBox>
            </div>
        </>
    )
}
